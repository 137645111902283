import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import './OrderComplete.css';

const OrderComplete = () => {
  return (
    <div id="ordercom-container" style={{ fontFamily: 'Nanum Gothic, Roboto' }}>
      <span id="modal-title" style={{ fontSize: '1.5rem', fontWeight: 800 }}>
        주문이 완료되었습니다!
      </span>

      <div id="modal-buttons-wrap" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Link to="/shop">
          <button id="move-shop-button" className="button is-medium" style={{ marginRight: '10px' }}>
            주문계속하기
          </button>
        </Link>

        <Link to="/myorder">
          <button id="move-mypage-button" className="button is-medium">
            주문내역보기
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OrderComplete;
