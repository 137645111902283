// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import * as Api from '../utils/api';
import { formatPhoneNumber, getAuthorizationObj, setCookie } from '../utils/useful-functions';
import './Login.css';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false); // 모달 열림/닫힘 상태
  const [modalContent, setModalContent] = useState({ title: '', body: '', footer: '' }); // 모달 내용

  useEffect(() => {
    const { isLogin } = getAuthorizationObj();
    if (isLogin) {
      alert('이미 로그인 되어 있습니다.');
      window.location.href = '/';
    }
  }, []);

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const openModal = (title, body, footer) => {
    setModalContent({ title, body, footer });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedPhone = formatPhoneNumber(phoneNumber);
    const isPasswordValid = password.length >= 4;
    const isValidPhoneNumber = checkPhoneNumber(formattedPhone);

    if (!isValidPhoneNumber || !isPasswordValid) {
      openModal('로그인 실패', '아이디와 비밀번호 입력을 확인하세요.', '닫기');
      return;
    }

    try {
      const data = { phoneNumber: formattedPhone, password };
      const result = await Api.postNoToken('/api/users/login', data);
      const { accessToken, refreshToken, exp, userName, phoneArry2, userRole } = result.data;
      setCookie('refreshToken',refreshToken);
      localStorage.setItem('accessToken_exp', exp);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userPhone', phoneArry2);
      localStorage.setItem('userRole', userRole);
      localStorage.setItem('userName', userName);
      alert(`${userName}님 반갑습니다. 홈으로 이동합니다.`);
      window.location.href = '/';
    } catch (err) {
      openModal('로그인 실패', `로그인 실패: ${err.message}`, '닫기');
    }
  };

  const checkPhoneNumber = (input) => {
    const pattern = /^010-\d{4}-\d{4}$/;
    return pattern.test(input);
  };

  return (
    <div className="login-form-container">
      <form className="box login-form-box has-background-light" onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="phoneInput">휴대폰번호</label>
          <div className="control">
            <input
              className="input is-medium"
              id="phoneInput"
              type="tel"
              placeholder="010-1234-5678"
              value={phoneNumber}
              onChange={handlePhoneChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="passwordInput">비밀번호</label>
          <div className="control">
            <input
              className="input is-medium"
              id="passwordInput"
              type="password"
              placeholder="비밀번호를 입력해주세요."
              value={password}
              onChange={handlePasswordChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="btn-wrapper">
          <button className="button is-medium is-success mb-3" type="submit">로그인 하기</button>
          <button className="button is-medium is-link mb-3" href="/register">회원가입하기</button>
          <div className="kakao-login" id="kakaoButton">
            <a href="/api/users/kakao">
              <img style={{ width: '100%', height: '100%' }} src="/img/kakao_login_medium_narrow.png" alt="kakao" />
            </a>
          </div>
        </div>
      </form>

      {/* 모달 컴포넌트 */}
      {modalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{modalContent.title}</p>
              <button className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            <section className="modal-card-body">
              {modalContent.body}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-black" onClick={closeModal}>{modalContent.footer}</button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
