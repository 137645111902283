import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Home.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import * as Api from '../utils/api';
import { getAuthorizationObj, addCommas, checkToken, getCookie } from '../utils/useful-functions';

const Home = () => {
  const [hostname] = useState(window.location.hostname);
  const [homeData, setHomeData] = useState({});
  const [products, setProducts] = useState([]);
  const subdomain = hostname.split('.')[0];

  useEffect(() => {
    const initialize = async () => {
      if (getCookie('refreshToken')) {
        await checkToken();
      }

      const { isLogin } = getAuthorizationObj();

      if (isLogin) {
        try {
          const myinfoData = await Api.getYesToken('/api/users?attribute=fullName&attribute=role&attribute=phoneNumber&attribute=companyId');
          const { fullName, role, phoneNumber, companyId } = myinfoData.data;
          localStorage.setItem('userPhone', phoneNumber.split('-')[2]);
          localStorage.setItem('userRole', role);
          localStorage.setItem('userName', fullName);
          localStorage.setItem('companyId', companyId);
        } catch (error) {
          window.alert(error);
        }
      }

      try {
        const response = await Api.getNoToken(`/api/homes/homeName?homeName=${subdomain}`);
        setHomeData(response.data);
        getNewArrival(subdomain);
        requestNotificationPermission();
      } catch (error) {
        console.error('Error fetching home data:', error);
        window.alert('홈페이지 정보를 불러오는 중 오류가 발생했습니다.');
      }
    };

    initialize();
  }, [hostname]);

  useEffect(() => {
    if (homeData.backgroundImage) {
      document.body.style.backgroundImage = `url(${homeData.backgroundImage})`;
    }
  }, [homeData]);


  const getNewArrival = async (subdomain) => {
    try {
      const result = await Api.getNoToken(`/api/products?subdomain=${subdomain}`);
      setProducts(result.data.products);
      localStorage.setItem('products', JSON.stringify(result.data.products));
    } catch (err) {
      console.log(err.message);
    }
  };

  const requestNotificationPermission = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          alert('알림이 비활성화되어 있습니다. 시스템 설정에서 알림을 활성화해주세요.');
        }
      });
    }
  };

  const renderNewArrival = () => {
    return products.slice(0, 3).map((item) => {
      const { shortId, image, productName, price, stock } = item;
      const stockText = stock > 1 ? `남은수량: ${stock}개` : 'SOLD OUT';
      const textColor = stock > 1 ? 'blue' : 'red';
      const imageOpacity = stock > 0 ? 1 : 0.3;
      const alinkText = stock > 0 ? `/shop/${shortId}` : '';
 


      return (
        <li key={shortId} className="item-card"> {/* 고유한 key 추가 */}
          <a href={alinkText}>
            <div className="img-box">
              <img src={image} alt={productName} style={{ opacity: imageOpacity }} />
            </div>
            <div className="productName">
              {productName}
              <br />
              {addCommas(price)}원
            </div>
            <div className="stockInfo" style={{ color: textColor }}>
              {stockText}
            </div>
          </a>
        </li>
      );
    });
  };

  return (
    <div>
      <main className="main-wrapper" aria-label="main contents"     style ={{backgroundImage : `url(${homeData.backgroundImage})`}}>
        <div className="main-thumnail" aria-label="main thumnail">
          <section className="section">
            <div className="container">
              <motion.p
                className="subtitle"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse", // 확대 후 축소 반복
                ease: "easeInOut",
              }}
              >
                {homeData.subtitleMain}
              </motion.p>
              <br />
              <motion.p
                className="subtitle2"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 50,
                  damping: 30,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
              >
                {homeData.subtitleSub}
              </motion.p>
            </div>
          </section>

          <article className="main-new" aria-label="main new items">
            <div className="new-wrapper">
              <span className="new-title">
                <img src="/img/onsaleimage.png" alt="" />
              </span>
              <ul className="item-list is-mobile columns">{renderNewArrival()}</ul>
            </div>
          </article>

          <div className="promotion">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              autoplay={{ delay: 3000 }}
              loop={true}
              speed={2000}
              slidesPerView={3}
              spaceBetween={20}
              centeredSlides={true}
              pagination={{ clickable: true }}
              navigation
            >
              {homeData.swiperImages && homeData.swiperImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <figure className="image">
                    <img src={image} alt={`image-${index + 1}`} />
                  </figure>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
