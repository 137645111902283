import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react'; // ECharts for React를 임포트합니다.
import './Admin.css'; // 기존 CSS 파일
import * as Api from '../utils/api.js';
//import { addCommas} from '../utils/useful-functions';
import NavAdmin from '../components/NavAdmin.js';
import Members from '../components/admin/Members';
import Orders from '../components/admin/Orders';
import Products from '../components/admin/Products';
import Companies from '../components/admin/Companies';
import Homes from '../components/admin/Homes';
import Qnas from '../components/admin/Qnas';
import Categories from '../components/admin/Categories';


const Admin = () => {
  const [myCompanyId, setMyCompanyId] = useState(''); // 회사 ID를 상태로 관리
  const [userRole, setUserRole] = useState(''); // 사용자 역할을 상태로 관리
  const [dashboardData, setDashboardData] = useState(null); // 대시보드 데이터 상태
  const [loading, setLoading] = useState(true); // 데이터 로드 상태 관리
  const [selectedCategory, setSelectedCategory] = useState('대시보드'); // 기본 선택 카테고리
  const [dashboardError, setDashboardError] = useState(false); // 에러 상태 추가

  useEffect(() => {
    const initializeDashboard = async () => {
      const storedUserRole = localStorage.getItem('userRole');
      const isAdminUser = storedUserRole === 'admin' || storedUserRole === 'seller';

      if (!isAdminUser) {
        window.alert('관리자 권한이 없습니다.');
        window.location.href = '/';
        return;
      }

      setUserRole(storedUserRole); // 사용자 역할 상태 설정

      try {
        const myInfo = await Api.postYesToken('/api/users/adminget', {});
        const { fullName, role } = myInfo.data;
        const companyId = myInfo.data.companyId ? myInfo.data.companyId._id : '';

        setUserRole(role); // 사용자 역할 상태 설정
        setMyCompanyId(companyId); // 회사 ID 상태 설정

        if (fullName !== localStorage.getItem('userName') || role !== localStorage.getItem('userRole')) {
          if (window.confirm('회원정보 읽기에 오류가 발생했습니다. 재로그인 해주세요')) {
            window.location.href = '/login';
          }
        }

        if (role === 'seller' && !companyId) {
          if (window.confirm('판매자ID와 판매 회사ID 간 연결이 없습니다. 관리자에 문의해주세요')) {
            window.location.href = '/';
          }
        }

        const dashboardData = await Api.getYesToken('/api/orders/admin/dashboard');
        setDashboardData(dashboardData); // 대시보드 데이터 상태 설정
        setLoading(false); // 데이터 로드 완료
      } catch (error) {
        console.error('Error fetching user information or dashboard data:', error);
        setDashboardError(true); // 에러 처리
        setLoading(false); // 데이터 로드 실패 시에도 로딩 중단
      }
    };

    initializeDashboard();
  }, [myCompanyId, userRole]); // myCompanyId와 userRole 상태가 변경될 때마다 실행

  const handleCategoryClick = (category) => {
    setSelectedCategory(category); // 카테고리 선택 시 상태 업데이트
  };

  const renderContent = () => {
    switch (selectedCategory) {
      case '회원':
        return userRole === 'admin' ? <Members /> : <p>조회 권한이 없습니다.</p>;
      case '판매자':
        return <Companies role={userRole} />;
       case '주문':
         return <Orders role={userRole} companyId={myCompanyId} />;
       case '상품':
         return <Products role={userRole} companyId={myCompanyId} />;
       case '문의':
         return <Qnas role={userRole} companyId={myCompanyId} />;
       case '홈편집':
        return <Homes role={userRole} />;
      case '카테고리':
        return userRole === 'admin' ? <Categories /> : <p>조회 권한이 없습니다.</p>;
      default:
        return <p>카테고리를 선택하세요.</p>;
    }
  };

  // 대시보드 그래프 렌더링
  const renderDashboard = () => {
    if (loading) {
      return <p>Loading charts...</p>;
    }

    if (dashboardError) {
      return <p>대시보드 데이터를 불러오는 중 문제가 발생했습니다.</p>;
    }

    const dates = Array.from(
      new Set(Object.values(dashboardData.salesData).flatMap((product) => Object.keys(product.dailySales)))
    ).sort();

    const productSalesOption = {
      title: { text: '상품별 판매량' },
      tooltip: { trigger: 'axis' },
      legend: {
        data: Object.values(dashboardData.salesData).map((product) => product.productName),
        bottom: 0
      },
      xAxis: { type: 'category', data: dates },
      yAxis: { type: 'value' },
      series: Object.values(dashboardData.salesData).map((product) => ({
        name: product.productName,
        type: 'line',
        data: dates.map((date) => (product.dailyQuantity[date] != null ? product.dailyQuantity[date] : 0))
      }))
    };

    const totalRevenueOption = {
      title: { text: '일별 총매출' },
      tooltip: { trigger: 'axis' },
      xAxis: { type: 'category', data: dates },
      yAxis: { type: 'value' },
      series: [
        {
          name: 'Total Revenue',
          type: 'bar',
          data: dates.map((date) =>
            Object.values(dashboardData.salesData).reduce((sum, product) => sum + (product.dailySales[date] || 0), 0)
          )
        }
      ]
    };

    return (
      <div className="columns is-multiline">
        <div className="column is-half">
          <ReactECharts option={productSalesOption} style={{ height: '400px', width: '100%' }} />
        </div>
        <div className="column is-half">
          <ReactECharts option={totalRevenueOption} style={{ height: '400px', width: '100%' }} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <NavAdmin onCategorySelect={handleCategoryClick} /> 
      <div className="admin-main">
        <div className="main-container-wrapper">
            
          <article className="wrapper-rendering">
          {selectedCategory !== '대시보드' && (  
            <div className="title-wrap" style={{ backgroundColor: 'rgb(235, 231, 231)', margin: '10px 0', display: 'flex', alignItems : 'flex-start'  }}>
              <div className="title-name button is-dark is-medium is-rounded" style={{ height: '100%' }}> {selectedCategory} </div>
            </div>)}
            {selectedCategory === '대시보드' && (
            <div className="container rendering">
              <div className="columns is-multiline">
                <div className="column is-one-quarter">
                <div className="card card-clickable" onClick={() => handleCategoryClick('주문')}>
                    <div className="card-content">
                      <h2 className="title is-5">오늘의 주문건</h2>
                      <p className="subtitle is-4"><span id="todayOrders">{dashboardData?.todayOrders}</span></p>
                    </div>
                  </div>
                </div>
                <div className="column is-one-quarter">
                  <div className="card">
                  <div className="card-content">
                    <h2 className="title is-5">오늘의 매출</h2>
                    <p className="subtitle is-4"><span id="todayRevenue">{dashboardData?.todayRevenue}</span>원</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-quarter">
              <div className="card card-clickable" onClick={() => handleCategoryClick('주문')}>
                  <div className="card-content">
                    <h2 className="title is-5">누적 주문</h2>
                    <p className="subtitle is-4"><span id="totalOrders">{dashboardData?.totalOrders}</span></p>
                  </div>
                </div>
              </div>
              <div className="column is-one-quarter">
                <div className="card">
                  <div className="card-content">
                    <h2 className="title is-5">누적 매출</h2>
                    <p className="subtitle is-4"><span id="totalRevenue">{dashboardData?.totalRevenue}</span>원</p>
                  </div>
                </div>
              </div>
             </div>

             <div className="columns is-multiline">
              <div className="column is-one-third">
                <div className="card">
                  <div className="card-content">
                    <h2 className="title is-5">주문상태(누적)</h2>
                    <p>주문접수: <span id="statusReceived">{dashboardData?.statusCounts['주문접수']}</span></p>
                    <p>배송중: <span id="statusShipping">{dashboardData?.statusCounts['배송중']}</span></p>
                    <p>배송완료: <span id="statusDelivered">{dashboardData?.statusCounts['배송완료']}</span></p>
                  </div>
                </div>
              </div>
 
            </div> 
            {renderDashboard()}                 
            </div>)}
            {selectedCategory !== '대시보드' && renderContent()} 
          </article>         
     
         {/* 대시보드 렌더링 */}
        </div>
      </div>
    </div>
  );
};

export default Admin;
