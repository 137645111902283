import React, { useState, useEffect } from 'react';
import { addCommas, formatPhoneNumber, validateEmail, validatePhoneNumber } from '../../utils/useful-functions';
import * as Api from '../../utils/api';

const Qnas = ({ role, myCompanyId }) => {
  const [qnas, setQnas] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedQna, setSelectedQna] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const qnasResponse = await Api.getYesToken(`/api/qnas/admin/list`);
        setQnas(qnasResponse.data);

        const productsResponse = await Api.getYesToken(`/api/products/list`);
        setProducts(productsResponse.data);
      } catch (error) {
        console.error('Error fetching qnas:', error);
        window.alert(error);
      }
    };

    fetchData();
  }, []);

  const openQnaEditModal = async (qnaId, productName) => {
    try {
      const qnaData = await Api.getYesToken(`/api/qnas/${qnaId}`);
      setSelectedQna({ ...qnaData.data, productName });
      setModalVisible(true);
    } catch (error) {
      console.error('Error fetching qna:', error);
      window.alert(error);
    }
  };

  const closeEditModal = () => {
    setModalVisible(false);
    setSelectedQna(null);
  };

  const handleSave = async () => {
    try {
      const response = await Api.patchYesToken(`/api/qnas/admin`, selectedQna.shortId, {
        answerComment: selectedQna.answerComment,
      });
      window.alert('문의 정보 수정 완료');
      refreshQnas();
      closeEditModal();
    } catch (error) {
      console.error('Error updating qna:', error);
      window.alert(error);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('선택한 문의를 정말로 삭제하시겠습니까?');
    if (!confirmDelete) return;

    try {
      await Api.deleteYesToken(`/api/qnas`, selectedQna.shortId);
      window.alert('문의 삭제 완료');
      refreshQnas();
      closeEditModal();
    } catch (error) {
      console.error('Error deleting qna:', error);
      window.alert(error);
    }
  };

  const refreshQnas = async () => {
    try {
      const qnasResponse = await Api.getYesToken(`/api/qnas/admin/list`);
      setQnas(qnasResponse.data);
    } catch (error) {
      console.error('Error refreshing qnas:', error);
      window.alert(error);
    }
  };

  return (
    <div>
      <section className="tabs is-boxed">
        <ul>
          <li className="is-active">
            <a>
              <span className="icon is-small">
                <i className="fa-regular fa-user" aria-hidden="true"></i>
              </span>
              <span id="totalcount">전체 ({qnas.length})</span>
            </a>
          </li>
        </ul>
      </section>

      <table className="table is-fullwidth" id="order-list-wrapper">
        <thead>
          <tr>
            <th id="pc-only">shortId</th>
            <th id="pc-mobile">상품명</th>
            <th id="pc-mobile">문의자</th>
            <th id="pc-only">문의내용</th>
            <th id="pc-mobile">문의일시</th>
            <th id="pc-mobile">답변자</th>
            <th id="pc-only">답변내용</th>
            <th id="pc-mobile">답변일시</th>
            <th id="pc-mobile">상세수정</th>
          </tr>
        </thead>
        <tbody>
          {qnas.map((qna) => {
            const qnaProduct = products.find((product) => product.shortId === qna.productId) || {};
            return (
              <tr key={qna.shortId} className={qna.shortId}>
                <td id="pc-only">{qna.shortId}</td>
                <td id="pc-mobile">{qnaProduct.productName}</td>
                <td id="pc-mobile">{qna.fullName}</td>
                <td id="pc-only">{qna.comment.substr(0, 9)}...</td>
                <td id="pc-mobile">{qna.createdAt.substr(0, 10)}</td>
                <td id="pc-mobile">{qna.answerFullName || ''}</td>
                <td id="pc-only">{qna.answerComment ? qna.answerComment.substr(0, 9) : ''}...</td>
                <td id="pc-mobile">{qna.answerDate ? qna.answerDate.substr(0, 10) : ''}</td>
                <td id="pc-mobile">
                  <button
                    className="button is-small"
                    onClick={() => openQnaEditModal(qna.shortId, qnaProduct.productName)}
                  >
                    상세
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {modalVisible && (
        <div className="modal is-active" id="qna-edit-modal">
          <div className="modal-background" onClick={closeEditModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">문의정보</p>
              <button className="delete close-button" onClick={closeEditModal}></button>
            </header>
            <section className="modal-card-body">
              <ul>
                <li>문의자 / userId: {selectedQna.fullName} / {selectedQna.userId}</li>
                <li>상품명 / productId: {selectedQna.productName} / {selectedQna.productId}</li>
                <li>등록일시: {selectedQna.createdAt}</li>
                <li><img className="img-qna-preview" src={selectedQna.image} alt="문의 이미지" /></li>
                <li>문의내용: <strong>{selectedQna.comment}</strong></li>
              </ul>
              <div>--------------------------</div>
              <ul>
                <li>답변자 / userId: {selectedQna.answerFullName || ''} / {selectedQna.answerWriteId || ''}</li>
                <li>등록일시: {selectedQna.answerDate || ''}</li>
              </ul>
              <div className="field">
                <label htmlFor="answer-comment">답변 내용</label>
                <textarea
                  id="answer-comment"
                  className="textarea"
                  value={selectedQna.answerComment || ''}
                  onChange={(e) => setSelectedQna({ ...selectedQna, answerComment: e.target.value })}
                ></textarea>
              </div>
              <div className="button-wrap">
                <button className="button is-warning" onClick={handleSave}>
                  {selectedQna.answerDate ? '수정하기' : '등록하기'}
                </button>
                <button className="button is-black" onClick={handleDelete}>삭제하기</button>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default Qnas;
