// src/pages/DetailPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as Api from '../utils/api';
import { addCommas,getAuthorizationObj } from '../utils/useful-functions';
import StarRating from '../components/StarRating'; 
import ImageResizer from '../components/ImageResizer';

import './Detail.css';

const Detail = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [isLogin,setIsLogin] = useState(false);

  // 리뷰 관련 상태
  const [reviews, setReviews] = useState([]);
  const [reviewComment, setReviewComment] = useState('');
  const [reviewScore, setReviewScore] = useState(5);
  const [reviewImageFile, setReviewImageFile] = useState(null);
  const [reviewImagePreview, setReviewImagePreview] = useState('');
  const [editingReview, setEditingReview] = useState(null);
  const [isReviewFormVisible, setIsReviewFormVisible] = useState(false);
  const [averageReviewScore, setAverageReviewScore] = useState(0);
  const [isLoading,setIsloading] = useState(true);

  // Q&A 관련 상태
  const [qnas, setQnas] = useState([]);
  const [qnaComment, setQnaComment] = useState('');
  const [qnaIsSecret, setQnaIsSecret] = useState(false);
  const [qnaImageFile, setQnaImageFile] = useState(null);
  const [qnaImagePreview, setQnaImagePreview] = useState('');
  const [editingQna, setEditingQna] = useState(null);
  const [isQnaFormVisible, setIsQnaFormVisible] = useState(false);

  const [selectedCount, setSelectedCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [activeTab, setActiveTab] = useState('detail');

  const [currentUserId, setCurrentUserId] = useState('');

  const fetchReviews = useCallback(async () => {
    try {
      
      const result = isLogin?  await Api.getYesToken(`/api/reviews/product/${productId}`) :await Api.getNoToken(`/api/reviews/product/${productId}`) ;
      console.log(result);
      const reviewList = result.data.reviews;
      setReviews(reviewList);
      setCurrentUserId(result.data.currentUserId);
      
      // 평균 리뷰 평점 계산
      if (reviewList.length > 0) {
        const totalScore = reviewList.reduce((sum, review) => sum + review.score, 0);
        setAverageReviewScore((totalScore / reviewList.length).toFixed(1));
      } else {
        setAverageReviewScore(0);
      }
    } catch (err) {
      console.error('리뷰를 가져오는 중 오류가 발생했습니다.', err);
    }
  }, [productId,isLogin]);

  const fetchQnas = useCallback(async () => {
    try {
      const result = isLogin? await Api.getYesToken(`/api/qnas/product/${productId}`):await Api.getNoToken(`/api/qnas/product/${productId}`);
      setQnas(result.data.qnas);
      setCurrentUserId(result.data.currentUserId);
      
    } catch (err) {
      console.error('Q&A를 가져오는 중 오류가 발생했습니다.', err);
    }
  }, [productId,isLogin]);

  useEffect(() => {
    
    const { isLogin } = getAuthorizationObj();
    setIsLogin(isLogin);
    
    const fetchProductDetail = async () => {

      try {
        setIsloading(true);
        const { data } = await Api.getNoToken('/api/products', productId);
        setProductData(data);
        setTotalPrice(data.price);
        fetchReviews();
        fetchQnas();
  
      } catch (error) {
        console.error('상품 정보를 가져오는 중 오류가 발생했습니다.', error);
      } finally {
        setIsloading(false);

      }
    };


    fetchProductDetail();

  }, [productId, fetchReviews, fetchQnas]);

  const handleCountChange = (event) => {
    const count = Number(event.target.value.replace('개', ''));
    setSelectedCount(count);
    if (productData) {
      setTotalPrice(count * productData.price);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleReviewWriteClick = () => {
    setEditingReview(null);
    setReviewComment('');
    setReviewScore(5);
    setIsReviewFormVisible(true); // 폼 열기
  };

  const handleReviewCancel = () => {
    setReviewComment('');
    setReviewImageFile(null);
    setReviewImagePreview('');
    setReviewScore(5);
    setIsReviewFormVisible(false); // 폼 닫기

  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    const image = await uploadImageToS3(reviewImageFile);

    try {
      if (editingReview) {
        await Api.patchYesToken(`/api/reviews`, editingReview.shortId, {
          comment: reviewComment,
          image,
          score: reviewScore,
        });
        alert('리뷰가 수정되었습니다.');
        setEditingReview(null);
      } else {
        await Api.postYesToken(`/api/reviews/${productId}`, {
          comment: reviewComment,
          image,
          score: reviewScore,
        });
        alert('리뷰가 등록되었습니다.');
      }
      fetchReviews();
      setReviewComment('');
      setReviewScore(5);
      setReviewImageFile(null);
      setReviewImagePreview('');
      setIsReviewFormVisible(false); // 폼 닫기
    } catch (err) {
      alert(err.message);
    }
  };

  // const handleReviewImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   setReviewImageFile(file);

  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     setReviewImagePreview(event.target.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleQnaWriteClick = () => {
    setEditingQna(null);
    setQnaComment('');
    setQnaIsSecret(false);
    setIsQnaFormVisible(true); // Q&A 폼 열기
  };

  const handleQnaCancel = () => {
    setIsQnaFormVisible(false); // Q&A 폼 닫기
    setQnaImageFile(null);
    setQnaImagePreview('');
  };

  const handleQnaSubmit = async (e) => {
    e.preventDefault();
    const image = await uploadImageToS3(qnaImageFile);

    try {
      if (editingQna) {
        await Api.patchYesToken(`/api/qnas`, editingQna.shortId, {
          comment: qnaComment,
          image,
          qnaIsSecret,
        });
        alert('문의가 수정되었습니다.');
        setEditingQna(null);
      } else {
        await Api.postYesToken(`/api/qnas/${productId}`, {
          comment: qnaComment,
          image,
          qnaIsSecret,
        });
        alert('문의가 등록되었습니다.');
      }
      fetchQnas();
      setQnaComment('');
      setQnaIsSecret(false);
      setQnaImageFile(null);
      setQnaImagePreview('');
      setIsQnaFormVisible(false); // 폼 닫기
    } catch (err) {
      alert(err.message);
    }
  };

  // const handleQnaImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   setQnaImageFile(file);

  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     setQnaImagePreview(event.target.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const uploadImageToS3 = async (imageFile) => {
    const folderName = 'reviewqna';
    if (!imageFile) return '';
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const uploadResult = await fetch(
        `/api/images/upload?folderName=${folderName}`,
        { method: 'POST', body: formData }
      );
      const result = await uploadResult.json();
      return result.imagePath;
    } catch (err) {
      console.error('이미지 업로드 중 오류 발생:', err);
    }
  };

  const handleReviewDelete = async (reviewId) => {
    if (!window.confirm('선택한 리뷰를 정말로 삭제하시겠습니까?')) return;
    try {
      await Api.deleteYesToken(`/api/reviews`, reviewId);
      alert('리뷰가 삭제되었습니다.');
      fetchReviews();
    } catch (err) {
      alert(err.message);
    }
  };

  const handleQnaDelete = async (qnaId) => {
    if (!window.confirm('선택한 문의사항을 정말로 삭제하시겠습니까?')) return;
    try {
      await Api.deleteYesToken(`/api/qnas`, qnaId);
      alert('문의가 삭제되었습니다.');
      fetchQnas();
    } catch (err) {
      alert(err.message);
    }
  };

  const handleReviewImageResize = (resizedFile) => {
    setReviewImageFile(resizedFile);
  };

  const handleQnaImageResize = (resizedFile) => {
    setQnaImageFile(resizedFile);
  };

  const isCurrentUser = (userId) => currentUserId === userId;
  const productCompany = productData?.companyId || {};

  return (
    <div classNme = "detail-main">
      <section className="section" id="main-section">
        <div className="wrapper">
          {/* 상품 정보 */}
          <div className="columns">
            <div className="column is-half">
              <figure className="img_box" id="product-image-box">
                {productData && <img src={productData.image} alt="Product" />}
              </figure>
            </div>
            <div className="column is-half">
              <div className="box" id="product-info">
                {productData && (
                  <>              
                    <div className="title-bar title is-5">상품정보</div> 
                    <ul>
                      <li style={{ marginBottom: '1.5rem' }}>
                        <strong style={{ color: 'blue', fontSize: '1.5rem' }}>{productData.productName}</strong>
                      </li>
                      <li> <strong>{productData.description}</strong> </li>
                      <li style={{ marginTop: '0.7rem', marginBottom: '0.7rem' }}>
                        <strong style={{ color: 'blue' }}>판매가격: {addCommas(productData.price)} 원</strong>
                      </li>
                      <li>
                        <strong>
                          남은수량:{' '}
                          <span
                            style={{
                              color: productData.stock > 0 ? 'blue' : 'red',
                            }}
                          >
                            {productData.stock > 0
                              ? `${productData.stock}개 남음`
                              : '품절'}
                          </span>
                        </strong>
                      </li>

                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        
        {!isLoading && productData && (        
          <div className="wrapper">
           <div className="columns equal-height-columns" id="additional-info">
            <div className="column is-half">
              <div id="delivery-info">
                <div className="title-bar-grey title is-6">배송정보</div>
                <ul>
                    <li><strong>택배비:</strong> 무료</li>
                    <li><strong>택배사:</strong> {productCompany.takbae || '정보없음'}</li>
                    <li><strong>배송안내:</strong> 입금이 확인되면 다음날 배송 시작되며 1 ~ 2일 소요됩니다.</li>
                </ul>
              </div>
            </div>
            <div className="column is-half">
              <div id="seller-info">
                <div className="title-bar-grey title is-6">판매자 정보</div>
                <ul>
                  <li><strong>판매자:</strong> {productCompany.companyName|| '정보없음'}</li>
                  <li><strong>연락처:</strong> {productCompany.phoneNumber|| '정보없음'}</li>
                  <li><strong>주소:</strong> {productCompany.senderAddress || '정보없음'}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>)}


        {/* 탭 섹션 */}
        <div className="wrapper-detail">
          <div className="tabs is-toggle is-fullwidth">
            <ul>
              <li className={activeTab === 'detail' ? 'is-active' : ''}>
                <a  href="#"
                    onClick={(e) => {
                    e.preventDefault();
                    handleTabChange('detail');
                  }}
                >
                  상세정보
                </a>
              </li>
              <li className={activeTab === 'review' ? 'is-active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabChange('review');
                  }}
                >
                  리뷰 ({reviews.length})
                </a>
              </li>
              <li className={activeTab === 'qna' ? 'is-active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabChange('qna');
                  }}
                >
                  Q&A ({qnas.length})
                </a>
              </li>
            </ul>
          </div>

          {/* 탭 내용 */}
          <div id="tab-content">
            {activeTab === 'detail' && productData && (
              <div id="tabs-detail-info-contents" className="tab-content">
                <img
                  src={productData.detailImage}
                  alt="Detail"
                  className="img_box_detail"
                />
              </div>
            )}

            {/* 리뷰 섹션 */}
            {activeTab === 'review' && (
              <div id="tabs-review-contents" className="tab-content">
                <div className="review-header">
                  <span className="review-title">상품 리뷰</span>
                  <div className="score-btn-wrap">
                    <div className="review-score-wrap">
                      <span className="review-score">
                        {averageReviewScore} / 5.0
                      </span>
                      <span className="review-count">({reviews.length}개의 후기)</span>
                    </div>
                    {isLogin && (<button
                      className="new-review button is-medium"
                      onClick={handleReviewWriteClick}
                    >
                      리뷰쓰기
                    </button>)}
                  </div>
                </div>

                {isReviewFormVisible && (
                  <form className="new-review-form" onSubmit={handleReviewSubmit}>
                    <div className="review-body card">
                      <div className="review-info">
                        <div className="info-score">
                        <span className="star">
                          <StarRating reviewScore={reviewScore} setReviewScore={setReviewScore} editable ={true} />
                        </span>
                        </div>
                      </div>
                      <div className="review-content">
                        <textarea
                          className="review-text"
                          placeholder="리뷰를 입력하세요."
                          value={reviewComment}
                          onChange={(e) => setReviewComment(e.target.value)}
                          autoFocus />
                      </div>
                      
                         <ImageResizer
                          onImageResize={handleReviewImageResize}
                          imageFile={reviewImageFile}
                          imagePreview={reviewImagePreview}
                          setImageFile={setReviewImageFile}
                          setImagePreview={setReviewImagePreview}
                        />
                      
                      <div className="review-modify">
                        <button className="button is-normal review-submit-button" type="submit">
                          등록
                        </button>
                        <button
                          className="button is-normal is-warning cancel-button"
                          type="button"
                          onClick={handleReviewCancel}
                        >
                          취소
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                <div className="review-body-wrap">
                  {reviews.length === 0 ? (
                    <div className="review-none">
                      <span>리뷰가 없습니다.</span>
                    </div>
                  ) : (
                    reviews.map((review) => (
                      <div key={review.shortId} className="review-body card">
                        <div className="review-info">
                          <div className="info-score">
                            <StarRating reviewScore={review.score} setReviewScore={review.score} editable ={false} />
                          </div>
                          <div className="info-username">
                            {`${review.fullName.charAt(0)} * *`}
                          </div>
                          <div className="info-date">
                            {review.createdAt.substr(0, 10)}
                          </div>
                        </div>
                        <div className="review-content">
                          <span className="content-review">{review.comment}</span>
                        </div>
                        {review.image && (
                          <div className="review-image">
                            <img
                              className="img-review"
                              src={review.image}
                              alt="Review"
                            />
                          </div>
                        )}
                        {isCurrentUser(review.userId) && (
                          <div className="review-modify">
                            <button
                              className="button review-mod-submit-button is-normal is-warning"
                              onClick={() => {
                                setEditingReview(review);
                                setReviewComment(review.comment); // 기존 코멘트
                                setReviewScore(review.score); // 기존 스코어
                                setReviewImageFile(null); // 이미지 파일 초기화
                                setReviewImagePreview(review.image); // 기존 이미지 URL로 미리보기 설정
                                setIsReviewFormVisible(true); // 폼을 열기
                              }}
                            >
                              수정
                            </button>
                            <button
                              className="button is-normal is-dark delete-button"
                              onClick={() => handleReviewDelete(review.shortId)}
                            >
                              삭제
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            {/* Q&A 섹션 */}
            {activeTab === 'qna' && (
              <div id="tabs-qna-contents" className="tab-content">
                <div className = "qna-btn-wrap">
                <span> </span>
                {isLogin && (<button
                  className="new-qna button is-medium"
                  onClick={handleQnaWriteClick}
                >
                  문의작성
                </button>)}
                </div>

                {isQnaFormVisible && (
                  <form className="new-qna-form" onSubmit={handleQnaSubmit}>
                    <div className="qna-body card">
                      <div id="is-secrete-wrap" style={{ marginTop: '10px' }}>
                        <label className="checkbox" style={{ margin: '10px', backgroundColor: 'beige' }}>
                          <input
                            type="checkbox"
                            id="is-secrete"
                            checked={qnaIsSecret}
                            onChange={(e) => setQnaIsSecret(e.target.checked)}
                          />{' '}
                          <strong> secret(비공개) </strong>
                        </label>
                      </div>
                      <div className="qna-content">
                        <textarea
                          className="qna-text"
                          placeholder="문의사항을 입력하세요."
                          value={qnaComment}
                          onChange={(e) => setQnaComment(e.target.value)}
                          autoFocus />
                      </div>
                      <ImageResizer
                        onImageResize={handleQnaImageResize}
                        imageFile={qnaImageFile}
                        imagePreview={qnaImagePreview}
                        setImageFile={setQnaImageFile}
                        setImagePreview={setQnaImagePreview}
                      />
                      <div className="qna-modify">
                        <button className="button is-normal qna-submit-button" type="submit">
                          등록
                        </button>
                        <button
                          className="button is-normal is-warning cancel-button"
                          type="button"
                          onClick={handleQnaCancel}
                        >
                          취소
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                <div className="qna-body-wrap">
                  {qnas.length === 0 ? (
                    <div className="qna-none">
                      <span>문의사항이 없습니다.</span>
                    </div>
                  ) : (
                    qnas.map((qna) => (
                      <div key={qna.shortId} className="qna-body card">
                        <div className="qna-info">
                          <div
                            className="info-secret"
                            style={{
                              color: qna.qnaIsSecret ? 'grey' : 'blue',
                            }}
                          >
                            {qna.qnaIsSecret ? '비공개' : '공개'}
                          </div>
                          <div className="info-username">
                            {`${qna.fullName.charAt(0)} * *`}
                          </div>
                          <div className="info-date">
                            {qna.createdAt.substr(0, 10)}
                          </div>
                        </div>
                        <div className="qna-content">
                          <span className="content-qna">{qna.comment}</span>
                        </div>
                        {qna.image && (
                          <div className="qna-image">
                            <img
                              className="img-review"
                              src={qna.image}
                              alt="QnA"
                            />
                          </div>
                        )}
                         <div className="qna-info" style={{ marginLeft: '1rem', paddingLeft: '0.5rem', backgroundColor: qna.answerDate ? '#FFE08A' : 'rgb(240,240,240)', color: 'grey' }}>
                            <span> {qna.answerDate? '답변완료' : '답변대기중'} </span> 
                          <div className="info-date"><span>{qna.answerDate?qna.answerDate.substr(0, 10):''}</span></div>
                         </div>
                        <div className="qna-content" style = {{marginLeft :'1rem', marginBottom:'1rem', backgroundColor:'rgb(240,240,240)', color:'grey'}} ><span className="content-qna">{qna.answerDate?qna.answerComment:''}</span>
                        </div> 
   

                        {isCurrentUser(qna.userId) && (
                          <div className="qna-modify">
                            <button
                              className="button qna-mod-submit-button is-normal is-warning"
                              onClick={() => {
                                
                                setEditingQna(qna);
                                setQnaComment(qna.comment); // 기존 코멘트
                                setQnaImageFile(null); // 이미지 파일 초기화
                                setQnaImagePreview(qna.image); // 기존 이미지 URL로 미리보기 설정
                                setIsQnaFormVisible(true); // 폼을 열기
                              }}
                            >
                              수정
                            </button>
                            <button
                              className="button is-normal is-dark delete-button"
                              onClick={() => handleQnaDelete(qna.shortId)}
                            >
                              삭제
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* 고정된 수량 선택 및 총 가격 */}
      <div className="fixed-box">
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <p style={{ marginRight: '0.5rem' }}>
            <strong>수량</strong>
          </p>
          <div className="control">
            <div className="select is-success is-small">
              <select
                id="total-count"
                onChange={handleCountChange}
                value={`${selectedCount}개`}
              >
                {[...Array(10).keys()].map((i) => (
                  <option key={i + 1}>{`${i + 1}개`}</option>
                ))}
              </select>
            </div>
          </div>
          <p
            id="totalPrice"
            style={{
              color: 'blue',
              fontSize: '1.1rem',
              fontWeight: 800,
              marginLeft: '0.5rem',
            }}
          >
            {addCommas(totalPrice)} 원
          </p>
        </div>
        <div style={{ width: '30%' }}>
          <button className="button is-medium is-success buyProduct"
           onClick={() => {
            const shortId = productData.shortId; // productData에서 shortId를 추출
            const selected_ = selectedCount; // 선택된 수량을 사용
            window.location.href = `/orderany?product=${shortId}&amount=${selected_}`;
          }}>
            주문진행
          </button>
        </div>
      </div>
    </div>
  );
};

export default Detail;
