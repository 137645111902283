import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import Nav from './components/Nav';
import Footer from './components/Footer'; // 새로 추가된 Footer 컴포넌트
import Home from './pages/Home';
import About from './pages/About'; // 예시로 추가된 다른 페이지 컴포넌트
import Login from './pages/Login';
import Admin from './pages/Admin';
import Detail from './pages/Detail';
import Shop from './pages/Shop';
import Myorder from './pages/Myorder';
import OrderDirect from './pages/OrderDirect';
import OrderComplete from './pages/OrderComplete';

const Layout = ({ children }) => {
  const location = useLocation();

  // Admin 경로에 있을 때는 NavAdmin을 사용하고, Footer를 제외합니다.
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <div className="app-container">
      {/* Admin 페이지일 때 NavAdmin을 보여주고, 나머지 경로에서는 일반 Nav */}
      {!isAdminRoute && <Nav />}

      {/* 라우트된 페이지 내용 */}
      <div className="react-content-container">
        {children}
      </div>

      {/* Admin 페이지가 아닐 때만 Footer를 렌더링 */}
      {!isAdminRoute && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} /> {/* Admin 경로 */}
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:productId" element={<Detail />} />
          <Route path="/myorder" element={<Myorder />} />
          <Route path="/orderany" element={<OrderDirect />} />
          <Route path="/ordercomplete" element={<OrderComplete />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;