import React from 'react';

const Footer = () => {
  return (
    <footer className="footer" style={{ padding: '3rem 1.5rem', backgroundColor: 'white', borderTop: '1px solid rgb(0 0 0 / 6%)' }}>
      <div className="footer-wrapper" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="footer-box">
          <figure className="image is-64x64">
            <img src="/img/mikkang-logo.png" alt="logo" />
          </figure>
        </div>
        <div className="footer-box" style={{ fontSize: '0.8rem' }}>
          <span>고향에서 재배한 상품의 지인 판매를 위한 사이트입니다.</span><br />
          <span style={{ color: 'blue' }}>개인정보는 철저히 암호화 처리하고 있습니다.^^</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;