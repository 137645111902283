import React, { useEffect, useState } from 'react';
import * as Api from '../../utils/api';
import { addCommas, formatPhoneNumber } from '../../utils/useful-functions';

const Members = () => {
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState('all');
  const [modalData, setModalData] = useState(null); // for modal data
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const [sellerCount, setSellerCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [totalMembersCount, setTotalMembersCount] = useState(0);

  // Fetch users based on selected role
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userQuery = selectedRole === 'all' ? '' : `?role=${selectedRole}`;
        const response = await Api.getYesToken(`/api/users/list${userQuery}`);
        const members = response.data;

        let sellerCounter = 0;
        let adminCounter = 0;

        members.forEach(member => {
          if (member.role === 'seller') sellerCounter += 1;
          if (member.role === 'admin') adminCounter += 1;
        });

        setUsers(members);
        setSellerCount(sellerCounter);
        setAdminCount(adminCounter);
        setTotalMembersCount(members.length);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [selectedRole]);

  const openModal = async (memberId) => {
    try {
      const response = await Api.getYesToken(`/api/users`, memberId);
      setModalData(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching member data:', error);
    }
  };

  const closeModal = () => {
    setModalData(null);
    setIsModalOpen(false);
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  return (
    <div className="container">

      <section className="tabs is-boxed">
        <ul>
          <li className={`userrender-full-li ${selectedRole === 'all' ? 'is-active' : ''}`}>
            <a onClick={() => handleRoleChange('all')} className="userrender-full-a">
              <span className="icon is-small">
                <i className="fa-regular fa-user" aria-hidden="true"></i>
              </span>
              <span id="totalmembers">전체 ({totalMembersCount})</span>
            </a>
          </li>
          <li className={`userrender-seller-li ${selectedRole === 'seller' ? 'is-active' : ''}`}>
            <a onClick={() => handleRoleChange('seller')} className="userrender-seller-a">
              <span className="icon is-small">
                <i className="fa-solid fa-user" aria-hidden="true"></i>
              </span>
              <span id="sellermembers">판매자 ({sellerCount})</span>
            </a>
          </li>
          <li className={`userrender-admin-li ${selectedRole === 'admin' ? 'is-active' : ''}`}>
            <a onClick={() => handleRoleChange('admin')} className="userrender-admin-a">
              <span className="icon is-small">
                <i className="fa-regular fa-user" aria-hidden="true"></i>
              </span>
              <span id="adminmembers">어드민 ({adminCount})</span>
            </a>
          </li>
        </ul>
      </section>

      <table className="table is-fullwidth" id="order-list-wrapper">
        <thead>
          <tr>
            <th>shortId</th>
            <th>이름</th>
            <th>전화번호</th>
            <th>이메일</th>
            <th>가입일</th>
            <th>변경일</th>
            <th>추천인</th>
            <th>Role</th>
            <th>수정</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.shortId}>
              <td>{user.shortId}</td>
              <td>{user.fullName}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.email}</td>
              <td>{user.createdAt.substr(0, 10)}</td>
              <td>{user.updatedAt.substr(0, 10)}</td>
              <td>{user.recommender}</td>
              <td>{user.role}</td>
              <td>
                <button
                  className="button is-small"
                  onClick={() => openModal(user.shortId)}
                >
                  상세
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for editing user details */}
      {isModalOpen && modalData && (
        <div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">유저 정보 수정</p>
              <button className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            <section className="modal-card-body">
              <form className="box register-user-form-box has-background-light" id="registerUserForm">
                <div className="field">
                  <label htmlFor="fullNameInput">이름</label>
                  <div className="control">
                    <input
                      className="input is-medium"
                      id="fullNameInput"
                      type="text"
                      value={modalData.fullName || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="emailInput">이메일</label>
                  <div className="control">
                    <input
                      className="input is-medium"
                      id="emailInput"
                      type="email"
                      value={modalData.email || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="phoneInput">휴대폰번호</label>
                  <div className="control">
                    <input
                      className="input is-medium"
                      id="phoneInput"
                      type="tel"
                      value={modalData.phoneNumber || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="userRoleInput">UserRole</label>
                  <div className="control">
                    <div className="select">
                      <select id="userRoleInput" value={modalData.role || ''} disabled>
                        <option value="basic_user">basic_user</option>
                        <option value="imsi_user">imsi_user</option>
                        <option value="seller">seller</option>
                        <option value="admin">admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="providerInput">provider</label>
                  <div className="control">
                    <textarea
                      className="textarea is-medium"
                      id="providerInput"
                      value={modalData.provider || ''}
                      readOnly
                    ></textarea>
                  </div>
                </div>
              </form>
            </section>
            <footer className="modal-card-foot">
              <button className="button" onClick={closeModal}>
                닫기
              </button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
