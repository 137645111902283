import React, { useState, useEffect } from 'react';
import * as Api from '../../utils/api.js';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 모달이 수정 모드인지 여부
  const [categoryForm, setCategoryForm] = useState({
    label: '',
    active: 'active',
  });

  // 카테고리 목록을 불러오는 API 호출
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Api.getNoToken('/api/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        window.alert('카테고리 정보를 불러오는 중 오류가 발생했습니다.');
      }
    };

    fetchCategories();
  }, []);

  // 모달 열기
  const openModal = (category = null) => {
    if (category) {
      setSelectedCategory(category);
      setCategoryForm({
        label: category.label,
        active: category.active,
      });
      setIsEditing(true);
    } else {
      setSelectedCategory(null);
      setCategoryForm({
        label: '',
        active: 'active',
      });
      setIsEditing(false);
    }
    document.getElementById('category-edit-modal').classList.add('is-active');
  };

  // 모달 닫기
  const closeModal = () => {
    document.getElementById('category-edit-modal').classList.remove('is-active');
  };

  // 입력값 변경 처리
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // 카테고리 저장 처리 (수정 또는 신규 등록)
  const handleSave = async () => {
    if (isEditing) {
      // 카테고리 수정
      try {
        await Api.patchYesToken(`/api/categories`, selectedCategory.shortId, categoryForm);
        window.alert('카테고리가 수정되었습니다.');
        closeModal();
        refreshCategories();
      } catch (error) {
        console.error('Error updating category:', error);
        window.alert('카테고리 수정 중 오류가 발생했습니다.');
      }
    } else {
      // 신규 카테고리 생성
      try {
        await Api.postYesToken('/api/categories', categoryForm);
        window.alert('카테고리가 등록되었습니다.');
        closeModal();
        refreshCategories();
      } catch (error) {
        console.error('Error creating category:', error);
        window.alert('카테고리 생성 중 오류가 발생했습니다.');
      }
    }
  };

  // 카테고리 삭제 처리
  const handleDelete = async () => {
    if (!selectedCategory) return;
    const confirmDelete = window.confirm('정말로 카테고리를 삭제하시겠습니까?');
    if (confirmDelete) {
      try {
        await Api.deleteYesToken(`/api/categories`, selectedCategory.shortId);
        window.alert('카테고리가 삭제되었습니다.');
        closeModal();
        refreshCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
        window.alert('카테고리 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  // 카테고리 목록 새로고침
  const refreshCategories = async () => {
    try {
      const response = await Api.getNoToken('/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error refreshing categories:', error);
    }
  };

  return (
    <div>
      <section>
        <div className="tabs is-boxed">
          <ul>
            <li className="is-active">
              <a>
                <span className="icon is-small">
                  <i className="fa fa-certificate" aria-hidden="true"></i>
                </span>
                <span>전체 ({categories.length})</span>
              </a>
            </li>
            <li>
              <a onClick={() => openModal()}>
                <span className="icon is-small">
                  <i className="fa fa-plus-square" aria-hidden="true"></i>
                </span>
                <span>신규 등록</span>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>shortId</th>
            <th>label</th>
            <th>Active</th>
            <th>상세수정</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.shortId}>
              <td>{category.shortId}</td>
              <td>{category.label}</td>
              <td>
                <button
                  className={`button is-small ${category.active === 'active' ? 'is-info' : 'is-warning'}`}
                >
                  {category.active}
                </button>
              </td>
              <td>
                <button className="button is-small" onClick={() => openModal(category)}>
                  상세
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="modal" id="category-edit-modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">카테고리 정보</p>
            <button className="delete" aria-label="close" onClick={closeModal}></button>
          </header>
          <section className="modal-card-body">
            <form className="box">
              <div className="field">
                <label htmlFor="categoryNameInput">카테고리명</label>
                <div className="control">
                  <input
                    className="input is-medium"
                    id="categoryNameInput"
                    type="text"
                    name="label"
                    placeholder="카테고리명을 입력해주세요."
                    value={categoryForm.label}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="categoryActive">Active</label>
                <div className="control">
                  <div className="select">
                    <select
                      id="categoryActive"
                      name="active"
                      value={categoryForm.active}
                      onChange={handleChange}
                    >
                      <option value="active">active</option>
                      <option value="deactive">deactive</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            {isEditing ? (
              <>
                <button className="button is-warning" onClick={handleSave}>
                  수정하기
                </button>
                <button className="button is-danger" onClick={handleDelete}>
                  삭제하기
                </button>
              </>
            ) : (
              <button className="button is-success" onClick={handleSave}>
                등록하기
              </button>
            )}
            <button className="button" onClick={closeModal}>
              취소
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Categories;
