import React, { useEffect, useState } from "react";
import * as Api from '../utils/api.js';
import "./Shop.css"

function Shop() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("전체보기");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [productCount, setProductCount] = useState(0);

  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  useEffect(() => {
    getCategoriesFromApi();
    getProductAll(1);
  }, []);

  const getCategoriesFromApi = async () => {
    const data = await Api.getNoToken('/api/categories');
    setCategories(data.data);
  };

  const getProductAll = async (page) => {
    const datas = await Api.getNoToken(`/api/products?page=${page}&subdomain=${subdomain}`);
    setProducts(datas.data.products);
  };

  const searchProducts = async (e) => {
    e.preventDefault();
    if (!searchKeyword) return;

    const datas = await fetch(`/api/search?keyword=${searchKeyword}`);
    const data = await datas.json();
    setProducts(data.data.products);
    setProductCount(data.data.productCount);
    setCategoryName(`'${searchKeyword}'에 대한 ${data.data.productCount}건의 검색 결과`);
  };

  const handleCategoryClick = async (categoryId, categoryLabel) => {
    setCategoryName(categoryLabel);
    const datas = await Api.getNoToken(`/api/categories/products/${categoryId}`);
    setProducts(datas.data.products);
  };

  return (
    <main>
      <div className="main-container-wrapper">
        <nav className="category-nav">
          <div id="category-list">
            <div className={`category all ${categoryName === "전체보기" ? "active" : ""}`}
                 onClick={() => {
                   setCategoryName("전체보기");
                   getProductAll(1);
                 }}>
              전체보기
            </div>
            {categories.map((category) => (
              category.active === "active" ?(<div className={`category ${categoryName === category.label ? "active" : ""}`}
                   key={category._id}
                   onClick={() => handleCategoryClick(category._id, category.label)}>
                {category.label}
              </div>):''
            ))}
          </div>
        </nav>

        <article className="container">
          <div className="title-wrap">
            <div className="title is-size-4 category-name">{categoryName}</div>
            <div className="search-wrap">
              <form onSubmit={searchProducts}>
                <input
                  className="is-medium search-input"
                  type="text"
                  placeholder="상품명을 입력하세요."
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
                <button className="is-medium search-button" type="submit">
                  <span className="material-icons">search</span>
                </button>
              </form>
            </div>
          </div>

          <div id="product-list">
            {products.map((product) => {
              let price = product.price.toLocaleString();
              let stockText = product.stock >= 30 ? "남은수량: 여유있음" :
                              product.stock > 1 ? `남은수량: ${product.stock}개` : "SOLDOUT";
              let textColor = product.stock >= 30 ? "blue" : product.stock > 1 ? "orange" : "red";
              let imageOpacity = product.stock > 1 ? 1.0 : 0.3;

              return (
                <div className="item-card" key={product.shortId}>
                  <a href={`/shop/${product.shortId}`}>
                    <div className="img-box">
                      <img src={product.image} alt="" style={{ opacity: imageOpacity }} />
                    </div>
                    <div className="productName">{product.productName}</div>
                    <div><span className="product-price">{price}원</span></div>
                    <div className="stockInfo" style={{ color: textColor }}>{stockText}</div>
                  </a>
                </div>
              );
            })}
          </div>

          <div className="bottom">
            <nav className="pagination is-centered" role="navigation" aria-label="pagination">
              <ul className="pagination-list">
                {/* Pagination logic goes here */}
              </ul>
            </nav>
          </div>
        </article>
      </div>
    </main>
  );
}

export default Shop;
