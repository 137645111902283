import React, { useEffect, useState } from 'react';
import { addCommas } from '../utils/useful-functions';
import * as Api from '../utils/api';
import './Myorder.css';

const MyOrder = () => {
  const [orderList, setOrderList] = useState([]);
  const [ongoingCount, setOngoingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [paymentPendingCount, setPaymentPendingCount] = useState(0); // 입금대기 내역
  const [activeTab, setActiveTab] = useState('ongoing'); // 현재 탭 상태
  const [payRenderMessage , setPayRenderMessage] =useState('');

  useEffect(() => {
    getOrderListInit();
  }, []);

  const getOrderListInit = async () => {
    let inProgressCount = 0;
    let completedCount = 0;
    let inactiveCount = 0;
    let paymentPendingCount = 0; // 입금 대기 건수

    try {
      const result = await Api.getYesToken('/api/orders/fullinfo');
      const resultData = result.data;

      let ongoingNewArray = [];
      resultData.forEach(order => {
        const orderStatus = order.orderStatus;
        const payStatus = order.payStatus;

        if (!order.active) {
          inactiveCount += order.products[0].count;
        } else if (orderStatus === '배송완료' && payStatus === '입금완료') {
          completedCount += order.products[0].count;
        } else {
          inProgressCount += order.products[0].count;
          ongoingNewArray.push(order);
        }

        // 입금 대기 상태인 주문
        if (payStatus === '입금대기') {
          paymentPendingCount += order.products[0].count;
        }
      });
      const paymentsStatistics = getOrdertPaymentsStatistics( resultData);
      const payRenderMessage_ = renderOrderStatistics (paymentsStatistics);
      setPayRenderMessage(payRenderMessage_);
      setOngoingCount(inProgressCount);
      setCompletedCount(completedCount);
      setInactiveCount(inactiveCount);
      setPaymentPendingCount(paymentPendingCount); // 입금 대기 내역 저장
      setOrderList(resultData); // 전체 주문 리스트 저장
    } catch (error) {
      console.error(error);
    }
  };

  // 탭 클릭 시 필터링하는 함수
  const filterOrderListByTab = () => {
    if (activeTab === 'completed') {
      return orderList.filter(order => order.orderStatus === '배송완료' && order.payStatus === '입금완료' && order.active);
    } else if (activeTab === 'inactive') {
      return orderList.filter(order => !order.active);
    }
    return orderList.filter(order => order.active && order.orderStatus !== '배송완료');
  };

  // 주문 내역이 없을 때 표시할 기본 박스
  const renderOrderList = () => {
    const filteredOrders = filterOrderListByTab();

    if (!filteredOrders.length) {
      return (
        <div className="no-order-list-box">
          <span className="no-order-list">주문 내역이 없습니다.</span>
        </div>
      );
    }

    return filteredOrders.map((order) => createOrderListElement(order));
  };

  const takbaelink = (takbae, songJangNumber, orderStatus) => {
    if (orderStatus === '주문접수') {
      window.alert('송장이 아직 생성되지 않았습니다.');
      return;
    }

    if (!songJangNumber) {
      window.alert('연결된 송장번호가 없습니다.');
      return;
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const width = isMobile ? '90%' : '400';
    const height = isMobile ? 'auto' : '900';

    if (takbae === '우체국') {
      window.open(
        `https://m.epost.go.kr/postal/mobile/mobile.trace.RetrieveDomRigiTraceList.comm?sid1=${songJangNumber}`,
        '',
        `width=${width},height=${height},scrollbars=no`
      );
    } else {
      window.open(
        `https://trace.cjlogistics.com/web/detail.jsp?slipno=${songJangNumber}`,
        '',
        `width=${width},height=${height},scrollbars=no`
      );
    }
  };

  const createOrderListElement = (item) => {
    const {
      orderStatus,
      payStatus,
      senderName,
      recipientName,
      recipientPhoneNumber,
      address,
      shortId,
      products,
      totalPrice,
      createdAt,
      takbae,
      songJangNumber,
      active,
    } = item;

    const createElementArray = products;
    createElementArray[0].senderName = senderName;
    createElementArray[0].recipientName = recipientName;
    createElementArray[0].recipientPhoneNumber = recipientPhoneNumber;
    createElementArray[0].shortaddress = `${address.address1}, ${address.address2}`;

    const orderDetail = createOrderDetailListElement(createElementArray);

    return (
      <li className="order box" key={shortId}>
        <div className="order-info">
          <div className="order-staus">
            <button
              className={`order-staus-button button ${getStatusButtonClass(orderStatus)}`}
              onClick={() => takbaelink(takbae, songJangNumber, orderStatus)}
            >
              <strong>{orderStatus}</strong>
            </button>
            <button className={`pay-staus-button button ${getPayStatusButtonClass(payStatus)}`}>
              <strong>{payStatus}</strong>
            </button>
          </div>
          <div className="order-id-wrap">
            <div>
              <strong>주문번호: </strong>
              <span className="order-id content">{shortId}</span>
              <strong> 주문일시:</strong>
              <span className="content">{createdAt.substr(0, 10)} ({createdAt.substr(11, 5)})</span>
            </div>
          </div>
        </div>
        <table className="table is-fullwidth">
          <thead>
            <tr className="oderlist_sheet">
              <th id="pc-only">제품</th>
              <th id="pc-mobile">품목</th>
              <th id="pc-only">보내는분</th>
              <th id="pc-mobile">받는분</th>
              <th id="pc-mobile">연락처</th>
              <th id="pc-mobile">주소</th>
              <th id="pc-mobile">수량</th>
            </tr>
          </thead>
          <tbody className="table-body">{orderDetail}</tbody>
        </table>
        <div className="order-info-2">
          <div className="order-price">
            <strong>총 금액: {addCommas(totalPrice)}원</strong>
          </div>
          <div className="order-delete">
           {active && (
            <a href={`/shop/${products[0].productId}`} className="button is-small is-warning">
              주문 추가
            </a>)}
            {active && payStatus === '입금대기' && (
            <button className="order-delete-button button is-small is-dark" onClick={onDeleteOrder}>
              주문 취소
            </button>
          )}
          </div>
        </div>
      </li>
    );
  };

  const createOrderDetailListElement = (array) => {
    return array.map(({ productImg, productName, count, senderName, recipientName, recipientPhoneNumber, shortaddress }) => (
      <tr key={productName}>
        <td id="pc-only"> <img className="order-img" src={productImg} alt="상품 이미지" /></td>
        <td id="pc-mobile">{productName}</td>
        <td id="pc-only" >{senderName}</td>
        <td id="pc-mobile">{recipientName}</td>
        <td id="pc-mobile">{recipientPhoneNumber}</td>
        <td id="pc-mobile">{shortaddress}</td>
        <td id="pc-mobile">{count}개</td>
      </tr>
    ));
  };

  const onDeleteOrder = async (e) => {
    e.preventDefault();
    const ok = window.confirm('주문 내역을 정말 삭제하시겠습니까?');
    if (!ok) return;

    try {
      const orderId = e.target.closest('.order').querySelector('.order-id').innerText;
      await Api.deleteYesToken('/api/orders', orderId);
      alert('주문 내역이 삭제되었습니다.');
      window.location.reload();
    } catch (err) {
      console.log(err.message);
    }
  };

  const getStatusButtonClass = (status) => {
    switch (status) {
      case '주문접수':
        return 'is-warning';
      case '배송시작':
        return 'is-success';
      case '배송완료':
        return 'is-info';
      default:
        return 'is-success';
    }
  };

  const getPayStatusButtonClass = (payStatus) => {
    switch (payStatus) {
      case '입금대기':
        return 'is-warning';
      case '입금확인중':
        return 'is-success';
      case '입금완료':
        return 'is-info';
      default:
        return 'is-white';
    }
  };

  const splitStringWithLineBreaks = (str, length = 5) => {
    let result = '';
    for (let i = 0; i < str.length; i += length) {
      result += str.substr(i, length) + '\n'; // 줄바꿈 문자를 추가
    }
    return result;
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('복사되었습니다.');
      })
      .catch((err) => {
        console.error('Failed to copy to clipboard: ', err);
      });
  };
  const getOrdertPaymentsStatistics = (data) => {
    const pendingPayments = data.filter(item => item.payStatus === "입금대기" && item.active === true);
      
    // 각 회사 정보를 추출하고 금액을 그룹화
    const paymentsByCompany = pendingPayments.reduce((acc, item) => {
      const { companyName, bankName, bankAccountOwner, bankAccountNo, active } = item.products[0].companyInfoShort;
      const companyKey = `${companyName}-${bankName}-${bankAccountOwner}-${bankAccountNo}`;
      const price = item.products[0].productPrice; 
      if (acc[companyKey]) {
        acc[companyKey].totalPrice += price;
      } else {
        acc[companyKey] = {
          companyName,
          bankName,
          bankAccountOwner,
          bankAccountNo,
          active,
          totalPrice: price
        };
      }
      return acc;
    }, {});
  
    return Object.values(paymentsByCompany);
  };
  const renderOrderStatistics = (datas) => {
    let totalPrice = 0;
    const isMobile = window.innerWidth <= 768;
  
    const rows = datas.map((data) => {
      totalPrice += data.totalPrice;
      const companyName = isMobile ? splitStringWithLineBreaks(data.companyName) : data.companyName;
      const bankAccountOwner = isMobile ? splitStringWithLineBreaks(data.bankAccountOwner) : data.bankAccountOwner;
  
      return (
        <tr key={data.bankAccountNo}>
          <td className={isMobile ? 'mobile-line-break' : ''}>{companyName}</td>
          <td style={{ color: 'blue' }}>{data.bankName}</td>
          <td className={isMobile ? 'mobile-line-break' : ''} style={{ color: 'blue' }}>
            {bankAccountOwner}
          </td>
          <td style={{ color: 'blue' }}>
            <a className="copy-tooltip" onClick={() => copyToClipboard(data.bankAccountNo)} style={{ color: 'blue' }}>
              {data.bankAccountNo}
            </a>
          </td>
          <td style={{ color: 'blue' }}>{addCommas(data.totalPrice)}</td>
        </tr>
      );
    });
  
    const message = totalPrice === 0 ? (
      <span>현재 입금이 필요한 주문건은 없습니다.</span>
    ) : (
      <>
        <span>
          입금이 필요한 금액은 총 <strong style={{ color: 'blue' }}>{addCommas(totalPrice)}원</strong> 입니다.
        </span>
        <table className="table is-fullwidth payment-table">
          <thead>
            <tr>
              <th>판매자</th>
              <th>은행</th>
              <th>예금주</th>
              <th style={{ color: 'blue' }}>계좌번호 <span style={{ fontSize: '0.5rem' }}>(복사)</span></th>
              <th>금액</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </>
    );
  
    return (
      <div className="statistics" style={{ borderColor: 'orange', borderWidth: '2px', borderStyle: 'solid' }}>
        {message}
      </div>
    );
  };


  return (
    <div>
      <section className="section" id="main-wrapper">
        <div className="title-wrap-myorder">
          <div className="title-name button is-dark is-medium is-rounded">내 주문내역</div>
        </div>
        {/* 입금 대기 내역이 있을 경우 표시 */}
        {paymentPendingCount > 0 && (
          <div className="payment-pending-info">
            <strong>입금 대기 중인 주문이 {paymentPendingCount}건 있습니다.</strong>
          </div>
        )}
        <div className="box statistics">
        {payRenderMessage}
        </div>
        <div className="tabs is-boxed myorder-tabs">
          <ul>
            <li className={`tab ${activeTab === 'ongoing' ? 'is-active' : ''}`} onClick={() => setActiveTab('ongoing')}>
              <a>
                <span className="icon is-small">
                  <i className="fa-solid fa-truck"></i>
                </span>
                <span>진행중({ongoingCount})</span>
              </a>
            </li>
            <li className={`tab ${activeTab === 'completed' ? 'is-active' : ''}`} onClick={() => setActiveTab('completed')}>
              <a>
                <span className="icon is-small">
                  <i className="fa-solid fa-folder"></i>
                </span>
                <span>완료건({completedCount})</span>
              </a>
            </li>
            <li className={`tab ${activeTab === 'inactive' ? 'is-active' : ''}`} onClick={() => setActiveTab('inactive')}>
              <a>
                <span className="icon is-small">
                  <i className="fa-solid fa-flag"></i>
                </span>
                <span>과거({inactiveCount})</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="section" id="list-wrapper">
        <ul className="order-list">{renderOrderList()}</ul>
      </section>
    </div>
  );
};

export default MyOrder;
