import React, { useState, useEffect } from 'react';
import * as Api from '../utils/api.js';
import { formatPhoneNumber, validateEmail, addCommas, setCookie, getAuthorizationObj } from '../utils/useful-functions.js';
import './OrderDirect.css';

const OrderDirect = () => {
  const [productInfo, setProductInfo] = useState(null);
  const [productCount, setProductCount] = useState(1);
  const [myPastOrderList, setMyPastOrderList] = useState([]);
  const [userIdDefine, setUserIdDefine] = useState(null);
  const { isLogin } = getAuthorizationObj();
  const [selectedAddress, setSelectedAddress] = useState('default'); // 기본 값은 'default'


  const [formData, setFormData] = useState({
    name: '',
    recipientName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    recipientPhone: '',
    postalCode: '',
    address1: '',
    address2: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    recipientName: false,
    email: false,
    password: false,
    phone: false,
    recipientPhone: false,
    postalCode: false,
  });

  const [defaultUserInfo, setDefaultUserInfo] = useState({
    fullName: '',
    phoneNumber: '',
    recipientName: '',
    recipientPhone: '',
    postalCode: '',
    address1: '',
    address2: '',
  });

  // 제품 정보 가져오기
  useEffect(() => {
    const productId = window.location.search.split('=')[1].split('&')[0];
    const productCount = parseInt(window.location.search.split('=')[2]) || 1;

    const fetchProductInfo = async (productId) => {
      try {
        const res = await Api.getNoToken(`/api/products/${productId}`);
        setProductInfo(res.data);
        setProductCount(productCount);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProductInfo(productId);
  }, []);

  // 과거 주문 목록 가져오기
  useEffect(() => {
    const fetchMyPastOrderList = async () => {
      try {
        const result = await Api.getYesToken('/api/orders/unique-orders');
        setMyPastOrderList(result.data);
      } catch (error) {
        console.error('Error fetching past orders:', error);
      }
    };

    if (isLogin) {
      fetchMyPastOrderList();
    }else{
      const userConfirm = window.confirm(
        `입력하신 주문자 정보를 바탕으로 임시회원으로 등록됩니다.
        \n주문이 진행되는 동안 로그인 후 주문조회/재주문 가능하며,
        \n행사기간이 종료된후 임시회원정보는 자동 삭제처리됩니다.
        \n계속진행하시겠습니까? `);
      if(!userConfirm) return;
    }
  }, [isLogin]);

  // 사용자 정보 가져오기
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const result = await Api.getYesToken('/api/users');
        const userData = result.data;
        setUserIdDefine(userData._id);
        
        // 기본 정보를 저장
        setDefaultUserInfo({
          fullName: userData.fullName,
          phoneNumber: userData.phoneNumber,
          recipientName: userData.fullName,
          recipientPhone: userData.phoneNumber,
          postalCode: userData?.address?.postalCode || '',
          address1: userData?.address?.address1 || '',
          address2: userData?.address?.address2 || '',
        });
  
        // 폼 데이터에도 기본 정보를 반영
        setFormData((prevData) => ({
          ...prevData,
          name: userData.fullName,
          recipientName: userData.fullName,
          phone: userData.phoneNumber,
          recipientPhone: userData.phoneNumber,
          postalCode: userData?.address?.postalCode || '',
          address1: userData?.address?.address1 || '',
          address2: userData?.address?.address2 || '',
        }));
      } catch (error) {
        console.error(error.message);
      }
    };
  
    if (isLogin) {
      fetchUserInfo();
    }
  }, [isLogin]);

  // 폼 데이터 변경 처리
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 유효성 검사 함수
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (formData.name.trim().length < 2) {
      newErrors.name = true;
      isValid = false;
    }
    if(!isLogin){
    if (!validateEmail(formData.email)) {
      newErrors.email = true;
      isValid = false;
    }

    if (formData.password !== formData.passwordConfirm || formData.password.length < 6) {
      newErrors.password = true;
      isValid = false;
    }
  };
    const phonePattern = /^010-\d{4}-\d{4}$/;
    if (!phonePattern.test(formData.phone)) {
      newErrors.phone = true;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  async function imsiLogin(phoneNumber, password) {
    try {
      const data = { phoneNumber, password };
  
      const result = await Api.postNoToken('/api/users/login', data);
      const { accessToken, refreshToken, exp, userName, phoneArry2, userRole } = result.data;
  
      // 로그인 성공 시 리프레시 토큰을 쿠키에 저장
      setCookie('refreshToken',refreshToken);
  
      localStorage.setItem('accessToken_exp', exp);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userPhone', phoneArry2);
      localStorage.setItem('userRole', userRole);
      localStorage.setItem('userName', userName);
  
    } catch (error) {
      console.error(error);
      window.confirm(`${phoneNumber}님 주문자 로그인 실패. \n${JSON.stringify(error.message)}`);
      throw new Error("임시 로그인 실패");
    }
  }
  
  // 폼 제출 처리
  const handleSubmit = async () => {
    if (!validateForm()) {
      alert('입력값을 확인해주세요.');
      return;
    }
  
    try {
      // 로그인이 되어 있지 않은 경우 임시 로그인을 처리
      if (!isLogin) {
        try {
          await imsiLogin(formData.phone, formData.password); // 임시 로그인 함수 호출
          alert('임시 로그인이 완료되었습니다.');
        } catch (loginError) {
          alert('임시 로그인에 실패했습니다. 다시 시도해주세요.');
          return;
        }
      }
  
      const productsList = [
        {
          productId: productInfo.shortId,
          productImg: productInfo.image,
          productName: productInfo.productName,
          count: productCount,
          productPrice: productInfo.price * productCount,
          companyId: productInfo.companyId,
        },
      ];
  
      const orderInfo = {
        senderName: formData.name,
        phoneNumber: formData.phone,
        recipientName: formData.recipientName,
        recipientPhoneNumber: formData.recipientPhone,
        address: {
          postalCode: formData.postalCode,
          address1: formData.address1,
          address2: formData.address2,
        },
        products: productsList,
        totalPrice: productInfo.price * productCount,
        userId: userIdDefine, // 임시 로그인된 사용자의 ID 사용
      };
  
      // 주문 처리
      await Api.postYesToken('/api/orders', orderInfo);
      alert('주문이 성공적으로 완료되었습니다.');
      window.location.href = '/ordercomplete';
    } catch (error) {
      alert('주문 등록 실패. 재시도해주세요.');
      console.error(error.message);
    }
  };
  // 과거 주문지 선택
  const handlePastAddressSelect = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === '' || selectedOption === '지난 배송지 선택') {
      resetForm();
    } else {
      const [recipientName, recipientPhoneNumber] = selectedOption.split('/');
      const selectedOrder = myPastOrderList.find(
        (order) => order.recipientName === recipientName && order.recipientPhoneNumber === recipientPhoneNumber
      );
      if (selectedOrder) {
        setFormData({
          ...formData,
          recipientName: selectedOrder.recipientName,
          recipientPhone: selectedOrder.recipientPhoneNumber,
          postalCode: selectedOrder.address.postalCode,
          address1: selectedOrder.address.address1,
          address2: selectedOrder.address.address2,
        });
      }
    }
  };

  // 폼 초기화
  const resetForm = () => {
    setFormData({
      name: '',
      recipientName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      phone: '',
      recipientPhone: '',
      postalCode: '',
      address1: '',
      address2: '',
    });
  };

  const handleAddressChange = (e) => {
    const selected = e.target.value;
    setSelectedAddress(selected);
  
    if (selected === 'default') {
      // 기본 주소 및 수신자 정보를 기본 정보로 되돌림
      setFormData({
        name: defaultUserInfo.fullName,
        recipientName: defaultUserInfo.recipientName,
        phone: defaultUserInfo.phoneNumber,
        recipientPhone: defaultUserInfo.recipientPhone,
        postalCode: defaultUserInfo.postalCode,
        address1: defaultUserInfo.address1,
        address2: defaultUserInfo.address2,
      });
    } else if (selected === 'new') {
      // 신규 주소 및 수신자 정보 필드 비우기
      setFormData((prevData) => ({
        ...prevData,
        recipientName: '',
        recipientPhone: '',
        postalCode: '',
        address1: '',
        address2: '',
      }));
    }
  };

  return (
    <div id="orderany-container">
      <header>
        <a href="/">
          <img src="../../img/yangsfarm_logo.png" alt="로고" />
        </a>
      </header>

      <div className="section">
        <div className="title">주문상품</div>
        {productInfo && (
          <div className="product-wrap">
            <div className="product-image-wrap">
              <img src={productInfo.image} alt="상품 사진" />
            </div>
            <div className="product-info-wrap">
              <div>상품명: {productInfo.productName}</div>
              <div>수량: {productCount}</div>
              <div>상품구매금액: {addCommas(productInfo.price * productCount)}원</div>
            </div>
          </div>
        )}
      </div>

      <div className="section">
        <div className="total-cost-wrap">
          <span className="title cost">상품 합계</span>
          <span id="total-cost">{productInfo && `${addCommas(productInfo.price * productCount)}원`}</span>
        </div>
      </div>

      <div className="section">
        <div className="title">주문자 정보</div>
        <div className="form-group">
          <label htmlFor="name">주문하시는 분</label>
          <input
            id="name"
            name="name"
            className={`input is-medium ${errors.name ? 'is-danger' : ''}`}
            type="text"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <div className="help is-danger">이름을 정확히 입력해주세요.</div>}
        </div>
        {!isLogin && <div className="form-group">
          <label htmlFor="emailinput">이메일주소</label>
          <input
            id="emailinput"
            name="email"
            className={`input is-medium ${errors.email ? 'is-danger' : ''}`}
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <div className="help is-danger">이메일을 정확히 입력해주세요.</div>}
        </div>}
        <div className="form-group">
          <label htmlFor="phone">연락처 (ID로 사용됩니다.)</label>
          <input
            id="phone"
            name="phone"
            className={`input is-medium ${errors.phone ? 'is-danger' : ''}`}
            type="tel"
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && <div className="help is-danger">연락처를 정확히 입력해주세요.</div>}
        </div>
        {!isLogin && <div className = "password-wrapper">
          <div className="form-group"  id="short-password">
          <label htmlFor="passwordInput">비밀번호<span style={{ fontSize: '0.6rem' }}>(영문+숫자 6~12자리)</span> </label>
          <input
            className={`input is-medium ${errors.password ? 'is-danger' : ''}`}
            id="passwordInput"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && <div className="help is-danger">규칙에 맞게 입력해주세요.</div>}
        </div>
        <div className="form-group"  id="short-password">
          <label htmlFor="passwordConfirmInput">비밀번호 확인</label>
          <input
            className={`input is-medium ${errors.password ? 'is-danger' : ''}`}
            id="passwordConfirmInput"
            name="passwordConfirm"
            type="password"
            value={formData.passwordConfirm}
            onChange={handleChange}
          />
          {errors.password && <div className="help is-danger">비밀번호가 일치하지 않습니다.</div>}
        </div>
        </div>}

        <hr />

        <div className="title">배송지 정보</div>
        <div id="select-address-wrap">
          <div id="default-address-wrap">
            <input
              type="radio"
              id="default-address"
              name="select-address"
              value="default"
              checked={selectedAddress === 'default'}
              onChange={handleAddressChange}
            />
            <label htmlFor="default-address">기본</label>
          </div>
          <div id="new-address-wrap">
            <input
              type="radio"
              id="new-address"
              name="select-address"
              value="new"
              checked={selectedAddress === 'new'}
              onChange={handleAddressChange}
            />
            <label htmlFor="new-address">신규</label>
          </div>
         <div className="select" id ="past-address-wrap">
         {isLogin &&  <select id="past-address" onChange={handlePastAddressSelect}>
            <option value="">지난 배송지 선택</option>
            {myPastOrderList.map((order, index) => (
              <option key={index} value={`${order.recipientName}/${order.recipientPhoneNumber}`}>
                {order.recipientName}/{order.recipientPhoneNumber}
              </option>
            ))}
          </select>}
        </div>
        </div>
       
        



        
        <div className="form-group">
          <label htmlFor="recipient-name">받으시는 분</label>
          <input
            id="recipient-name"
            name="recipientName"
            className={`input is-medium ${errors.recipientName ? 'is-danger' : ''}`}
            type="text"
            value={formData.recipientName}
            onChange={handleChange}
          />
          {errors.recipientName && <div className="help is-danger">이름을 정확히 입력해주세요.</div>}
        </div>
        <div className="form-group">
          <label htmlFor="recipient-phone">받으시는 분 연락처</label>
          <input
            id="recipient-phone"
            name="recipientPhone"
            className={`input is-medium ${errors.recipientPhone ? 'is-danger' : ''}`}
            type="tel"
            value={formData.recipientPhone}
            onChange={handleChange}
          />
          {errors.recipientPhone && <div className="help is-danger">연락처를 정확하게 입력해주세요.</div>}
        </div>

        <div className="form-group">
          <label htmlFor="postal-code">우편번호</label>
          <div id="postal-code-wrap" className="input-wrap">
            <input
              id="postal-code"
              name="postalCode"
              className={`input is-medium ${errors.postalCode ? 'is-danger' : ''}`}
              type="text"
              value={formData.postalCode}
              onChange={handleChange}
              disabled
            />    
          <button id="kakao_address" className="button is-medium is-responsive"
            onClick={() => {
            if (!window.daum) {
              const script = document.createElement('script');
              script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
              script.async = true;
              script.onload = () => {
                new window.daum.Postcode({
                  oncomplete: function (data) {
                  setFormData((prevState) => ({
                  ...prevState,
                  postalCode: data.zonecode,
                  address1: data.address,
                  address2: '', // 상세 주소는 비워둠
                }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                postalCode: false, // 에러 메시지 숨김 처리
              }));
                document.getElementById('address2').focus(); // 상세 주소로 포커스 이동
              },
                }).open();
              };
              document.body.appendChild(script);
             } else {
                new window.daum.Postcode({
                  oncomplete: function (data) {
                  setFormData((prevState) => ({
                    ...prevState,
                    postalCode: data.zonecode,
                    address1: data.address,
                    address2: '', // 상세 주소는 비워둠
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                  postalCode: false, // 에러 메시지 숨김 처리
                }));
                document.getElementById('address2').focus(); // 상세 주소로 포커스 이동
              },
              }).open();
            }
          }}
          >
          주소찾기
          </button>
          </div>
          {errors.postalCode && <div className="help is-danger">우편번호가 올바르지 않습니다.</div>}
        </div>

        <div className="form-group">
          <label htmlFor="address">주소</label>
          <div id="address-input-wrap" className="input-wrap">
            <input
              id="address1"
              name="address1"
              className="input is-medium"
              type="text"
              value={formData.address1}
              onChange={handleChange}
              disabled
            />
            <input
              id="address2"
              name="address2"
              className="input is-medium"
              type="text"
              placeholder="상세 주소를 입력해주세요"
              value={formData.address2}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group">
          <input type="checkbox" id="set-default-address" />
          <label htmlFor="set-default-address">기본 배송지 설정</label>
        </div>
      </div>
      <div id="bottom">
          <button id="pay-button" className="button is-large" onClick={handleSubmit}>
            {productInfo ? `${addCommas(productInfo.price * productCount)}원 주문하기` : '주문하기'}
          </button>
      </div>
    </div>
  );
};

export default OrderDirect;
