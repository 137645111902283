import React, { useState, useEffect, useCallback } from 'react';
import * as Api from '../../utils/api';
import { addCommas } from '../../utils/useful-functions';
import ImageResizer from '../../components/ImageResizer'; // ImageResizer 컴포넌트 사용

const Products = ({ role, companyId }) => {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  // fetchProducts 함수 정의 및 useCallback으로 메모이제이션
  const fetchProducts = useCallback(async () => {
    const companyQuery = role === 'admin' ? '' : `?companyId=${companyId}`;
    try {
      const response = await Api.getYesToken(`/api/products/list${companyQuery}`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      window.alert(error);
    }
  }, [role, companyId]);

  useEffect(() => {
    // 컴포넌트가 마운트되거나, role, companyId가 변경될 때만 fetchProducts 호출
    fetchProducts();
  }, [fetchProducts]);

  const openModal = (product = null) => {
    setSelectedProduct(product);
    setIsEditMode(!!product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleModalClose = () => {
    fetchProducts(); // 모달이 닫힐 때 제품 목록을 다시 불러옴
    closeModal();
  };

  const renderProductRows = () => {
    return products.map(product => (
      <tr key={product.shortId}>
        <td id="pc-only">{product.shortId}</td>
        <td id="pc-only">
          <img className="product-img-box" src={product.image} alt="상품 이미지" />
        </td>
        <td id="pc-mobile">{product.productName}</td>
        <td id="pc-mobile">{addCommas(product.price)}</td>
        <td id="pc-only">{product.description}</td>
        <td id="pc-only">{product.companyId?.companyName}</td>
        <td id="pc-only">{product.categoryId?.label}</td>
        <td id="pc-mobile">{product.stock}</td>
        <td id="pc-only">{product.registerId}</td>
        <td id="pc-mobile">
          <button className={`button is-small ${product.active === 'active' ? 'is-info' : 'is-warning'}`}>
            {product.active}
          </button>
        </td>
        <td id="pc-mobile">
          <button className="button is-small" onClick={() => openModal(product)}>
            수정
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container">
      <div className="tabs is-boxed">
        <ul>
          <li className="is-active">
            <a>
              <span className="icon is-small">
                <i className="fa fa-shopping-basket" aria-hidden="true"></i>
              </span>
              <span id="totalcount">전체({products.length})</span>
            </a>
          </li>
          <li>
            <a onClick={() => openModal()}>
              <span className="icon is-small">
                <i className="fa fa-plus-square" aria-hidden="true"></i>
              </span>
              <span>신규 등록</span>
            </a>
          </li>
        </ul>
      </div>

      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th id="pc-only">shortId</th>
            <th id="pc-only">이미지</th>
            <th id="pc-mobile">상품명</th>
            <th id="pc-mobile">가격</th>
            <th id="pc-only">설명</th>
            <th id="pc-only">판매사</th>
            <th id="pc-only">카테고리</th>
            <th id="pc-mobile">재고</th>
            <th id="pc-only">등록자</th>
            <th id="pc-mobile">Active</th>
            <th id="pc-mobile">수정</th>
          </tr>
        </thead>
        <tbody>{renderProductRows()}</tbody>
      </table>

      {isModalOpen && (
        <ProductModal
          product={selectedProduct}
          isEditMode={isEditMode}
          closeModal={handleModalClose}
        />
      )}
    </div>
  );
};

const ProductModal = ({ product, isEditMode, closeModal }) => {
  const [productName, setProductName] = useState(product ? product.productName : '');
  const [price, setPrice] = useState(product ? addCommas(product.price) : '');
  const [description, setDescription] = useState(product ? product.description : '');
  const [stock, setStock] = useState(product ? product.stock : 0);
  const [active, setActive] = useState(product ? product.active : 'active');
  const [companyId, setCompanyId] = useState(product?.companyId?._id || '');
  const [categoryId, setCategoryId] = useState(product?.categoryId?._id || '');

  const [mainImage, setMainImage] = useState(null);
  const [mainImagePreview, setMainImagePreview] = useState(product?.image || '');

  const [detailImage, setDetailImage] = useState(null);
  const [detailImagePreview, setDetailImagePreview] = useState(product?.detailImage || '');

  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);

  // 카테고리 및 판매사 데이터 불러오기
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const categoryResponse = await Api.getYesToken('/api/categories');
        setCategories(categoryResponse.data);

        const companyResponse = await Api.getYesToken('/api/companies/list');
        setCompanies(companyResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOptions();
  }, []);

  const handleSave = async () => {
    const productData = {
      productName,
      price: parseInt(price.replace(/,/g, ''), 10),
      description,
      stock,
      active,
      companyId,
      categoryId,
      image: mainImage,
      detailImage,
    };

    try {
      if (isEditMode) {
        await Api.patchYesToken(`/api/products`, product.shortId, productData);
        alert('상품 정보가 수정되었습니다.');
      } else {
        await Api.postYesToken('/api/products', productData);
        alert('새 상품이 등록되었습니다.');
      }
      closeModal(); // 모달이 닫힐 때 상태 업데이트 후 닫기
    } catch (error) {
      console.error('Error saving product:', error);
      window.alert(error);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{isEditMode ? '상품 수정' : '신규 상품 등록'}</p>
          <button className="delete" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          {/* 상품 이름 */}
          <div className="field">
            <label className="label">상품 이름</label>
            <input
              className="input"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          {/* 상품 가격 */}
          <div className="field">
            <label className="label">가격</label>
            <input
              className="input"
              value={price}
              onChange={(e) => setPrice(addCommas(e.target.value))}
            />
          </div>

          {/* 상품 설명 */}
          <div className="field">
            <label className="label">설명</label>
            <textarea
              className="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* 재고 */}
          <div className="field">
            <label className="label">재고</label>
            <input
              className="input"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            />
          </div>

          {/* 대표 이미지 */}
          <div className="field">
            <label className="label">대표 이미지</label>
            <ImageResizer
              imageFile={mainImage}
              imagePreview={mainImagePreview}
              setImageFile={setMainImage}
              setImagePreview={setMainImagePreview}
              onImageResize={(resizedImage) => setMainImage(resizedImage)}
              targetWidth={1000} // 원하는 이미지 리사이징 너비
            />
          </div>

          {/* 상세 이미지 */}
          <div className="field">
            <label className="label">상세 이미지</label>
            <ImageResizer
              imageFile={detailImage}
              imagePreview={detailImagePreview}
              setImageFile={setDetailImage}
              setImagePreview={setDetailImagePreview}
              onImageResize={(resizedImage) => setDetailImage(resizedImage)}
              targetWidth={1000} // 원하는 이미지 리사이징 너비
            />
          </div>

          {/* 카테고리 선택 */}
          <div className="field">
            <label className="label">카테고리</label>
            <div className="select">
              <select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                <option value="">카테고리 선택</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* 판매사 선택 */}
          <div className="field">
            <label className="label">판매사</label>
            <div className="select">
              <select value={companyId} onChange={(e) => setCompanyId(e.target.value)}>
                <option value="">판매사 선택</option>
                {companies.map((company) => (
                  <option key={company._id} value={company._id}>
                    {company.companyName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Active 상태 */}
          <div className="field">
            <label className="label">상태</label>
            <div className="select">
              <select value={active} onChange={(e) => setActive(e.target.value)}>
                <option value="active">Active</option>
                <option value="deactive">Deactive</option>
              </select>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={handleSave}>
            {isEditMode ? '수정하기' : '등록하기'}
          </button>
        </footer>
      </div>
    </div>
  );
};


export default Products;
