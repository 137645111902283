import React, { useEffect, useState } from 'react';
import * as Api from '../../utils/api.js';

const Companies = ({ role }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  useEffect(() => {
    // API 호출: 회원 목록 가져오기
    const fetchCompanies = async () => {
      try {
        const response = await Api.getYesToken('/api/companies/list');
        setCompanies(response.data);
      } catch (error) {
        console.error('Error fetching companies:', error);
        window.alert(error);
      }
    };

    fetchCompanies();
  }, []);

  const openCompanyEditModal = async (companyId) => {
    const companyData = await getCompanyDataById(companyId);
    setSelectedCompany(companyData);
    setIsPatch(true);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedCompany(null);
    setIsPatch(false);
    setIsModalOpen(false);
  };

  const getCompanyDataById = async (companyId) => {
    try {
      const response = await Api.getYesToken(`/api/companies/company?companyId=${companyId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching company:', error);
      window.alert(error);
    }
  };

  const renderCompaniesTable = () => {
    return companies.map((company) => (
      <tr key={company.shortId}>
        <td id="pc-only">{company.shortId}</td>
        <td id="pc-mobile">{company.companyName}</td>
        <td id="pc-mobile">{company.phoneNumber}</td>
        <td id="pc-mobile">{company.bankName}</td>
        <td id="pc-mobile">{company.bankAccountOwner}</td>
        <td id="pc-only">{company.bankAccountNo}</td>
        <td id="pc-only">{company.takbae}</td>
        <td id="pc-mobile">
          <button id="order-box" className={`button is-small ${company.active ? 'is-info' : 'is-warning'}`}>
            {company.active.toString()}
          </button>
        </td>
        <td id="pc-mobile">
          <button className="button is-small" onClick={() => openCompanyEditModal(company.shortId)}>
            상세
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <div className="tabs is-boxed">
        <ul>
          <li className="companyrender-full-list is-active">
            <a>
              <span className="icon is-small">
                <i className="fa-regular fa-user" aria-hidden="true"></i>
              </span>
              <span id="totalcount">전체({companies.length})</span>
            </a>
          </li>
          {role === 'admin' && (
            <li>
              <a className="companyrender-create" onClick={() => setIsModalOpen(true)}>
                <span className="icon is-small">
                  <i className="fa fa-plus-square" aria-hidden="true"></i>
                </span>
                <span>신규 등록</span>
              </a>
            </li>
          )}
        </ul>
      </div>

      <table className="table is-fullwidth" id="order-list-wrapper">
        <thead>
          <tr>
            <th id="pc-only">shortId</th>
            <th id="pc-mobile">판매자(사)명</th>
            <th id="pc-mobile">전화번호</th>
            <th id="pc-mobile">입금은행명</th>
            <th id="pc-mobile">예금주</th>
            <th id="pc-only">계좌번호</th>
            <th id="pc-only">택배사</th>
            <th id="pc-mobile">Active</th>
            <th id="pc-mobile">상세수정</th>
          </tr>
        </thead>
        <tbody>{renderCompaniesTable()}</tbody>
      </table>

      {isModalOpen && (
        <CompanyEditModal
          company={selectedCompany}
          isPatch={isPatch}
          onClose={closeEditModal}
        />
      )}
    </div>
  );
};

const CompanyEditModal = ({ company, isPatch, onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    bankName: '',
    bankAccountOwner: '',
    bankAccountNo: '',
    takbae: '',
    senderName: '',
    senderPhoneNumber: '',
    senderAddress: '',
    active: true,
    senderActive: true,
  });

  useEffect(() => {
    if (company) {
      setFormData({
        companyName: company.companyName || '',
        phoneNumber: company.phoneNumber || '',
        bankName: company.bankName || '',
        bankAccountOwner: company.bankAccountOwner || '',
        bankAccountNo: company.bankAccountNo || '',
        takbae: company.takbae || '',
        senderName: company.senderName || '',
        senderPhoneNumber: company.senderPhoneNumber || '',
        senderAddress: company.senderAddress || '',
        active: company.active,
        senderActive: company.senderActive,
      });
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // formData의 유효성 검사 (예: 빈 값 확인)
    if (!formData.companyName || !formData.phoneNumber || !formData.bankName) {
      window.alert('필수 항목을 입력해주세요.');
      return;
    }
  
    try {
      if (isPatch) {
        // 수정 (Patch) 요청
        const companyId = company.shortId;
        await Api.patchYesToken(`/api/companies`, companyId, formData);
        window.alert('판매자 정보 수정 완료');
      } else {
        // 생성 (Post) 요청
        await Api.postYesToken(`/api/companies/`, formData);
        window.alert('판매자 정보 생성 완료');
      }
  
      // API 호출 후 모달을 닫고 데이터 초기화
      onClose();
      // 데이터를 다시 로드하거나 업데이트하는 로직 추가
    } catch (error) {
      console.error('Error while submitting form:', error);
      window.alert('데이터 저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{isPatch ? '판매사 수정' : '판매사 등록'}</p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label>판매자(사)명</label>
            <input
              className="input"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="판매자(사)명을 입력해주세요."
            />
          </div>
          <div className="field">
            <label>전화번호</label>
            <input
              className="input"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="010-1234-5678"
            />
          </div>
          <div className="field">
            <label>입금은행명</label>
            <input
              className="input"
              name="bankName"
              value={formData.bankName}
              onChange={handleChange}
              placeholder="입금받을 은행명을 입력해주세요."
            />
          </div>
          <div className="field">
            <label>예금주</label>
            <input
              className="input"
              name="bankAccountOwner"
              value={formData.bankAccountOwner}
              onChange={handleChange}
              placeholder="예금주명을 입력해주세요."
            />
          </div>
          <div className="field">
            <label>계좌번호</label>
            <input
              className="input"
              name="bankAccountNo"
              value={formData.bankAccountNo}
              onChange={handleChange}
              placeholder="계좌번호를 입력해주세요."
            />
          </div>
          <div className="field">
            <label>택배사</label>
            <input
              className="input"
              name="takbae"
              value={formData.takbae}
              onChange={handleChange}
              placeholder="택배사명을 입력해주세요."
            />
          </div>
          <div className="field">
            <label>발송자명</label>
            <input
              className="input"
              name="senderName"
              value={formData.senderName}
              onChange={handleChange}
              placeholder="발송자명을 입력해주세요."
            />
          </div>
          <div className="field">
            <label>발송자 휴대폰번호</label>
            <input
              className="input"
              name="senderPhoneNumber"
              value={formData.senderPhoneNumber}
              onChange={handleChange}
              placeholder="010-1234-5678"
            />
          </div>
          <div className="field">
            <label>발송자 주소</label>
            <input
              className="input"
              name="senderAddress"
              value={formData.senderAddress}
              onChange={handleChange}
              placeholder="발송자 주소를 입력해주세요."
            />
          </div>
          {/* More form fields as needed */}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={handleSubmit}>
            {isPatch ? '수정하기' : '등록하기'}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Companies;
