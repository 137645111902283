// // src/pages/About.js
// import React, { useEffect } from 'react';
// import './About.css'; // CSS 파일 가져오기
// import { getNode } from '../utils/useful-functions.js';

// const About = () => {
//   useEffect(() => {
//     window.onpageshow = function (event) {
//       if (event.persisted) {
//         window.location.reload();
//       }
//     };

   
//     const $aboutWrapper = getNode('.about-wrapper');
//     const blogId = 'rokettory';
//     const rssUrl = `https://rss.blog.naver.com/${blogId}.xml`;
//     const defaultImageUrl = '/img/blog.png'; 
//     const desiredCategory = '제주소식';
//     const hostname = window.location.hostname;
//     const subdomain = hostname.split('.')[0];

//     fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(rssUrl)}`)
//       .then((response) => response.json())
//       .then((data) => {
//         const parser = new DOMParser();
//         const doc = parser.parseFromString(data.contents, 'application/xml');
//         const items = doc.querySelectorAll('item');

//         items.forEach((item) => {
//           const categories = Array.from(item.querySelectorAll('category')).map((cat) => cat.textContent);
//           if (!categories.includes(desiredCategory) && subdomain !== 'localhost' && subdomain !== 'store') {
//             return; 
//           }

//           const title = item.querySelector('title').textContent;
//           const link = item.querySelector('link').textContent;
//           const description = item.querySelector('description').textContent;

//           const imgRegex = /<img[^>]+src="([^">]+)"/g;
//           const imgMatch = imgRegex.exec(description);
//           const imageUrl = imgMatch ? imgMatch[1] : defaultImageUrl;

//           const textDescription = description.replace(/<[^>]*>?/gm, '');
//           const shortDescription = textDescription.split(' ').slice(0, 15).join(' ') + '...';

//           const blogLinkDiv = document.createElement('div');
//           blogLinkDiv.classList.add('column', 'is-two-third');
//           blogLinkDiv.innerHTML = `
//             <article class="media box" style="max-width:800px">
//               <figure class="media-left">
//                 <p class="image is-64x64">
//                   <img src="${imageUrl}" alt="Blog Thumbnail">
//                 </p>
//               </figure>
//               <div class="media-content">
//                 <p class="title is-5">${title}</p>
//                 <div class="content">
//                   ${shortDescription}
//                   <br>
//                   <a href="${link}" target="_blank">Read more</a>
//                 </div>
//               </div>
//             </article>
//           `;
//           $aboutWrapper.appendChild(blogLinkDiv);
//         });
//       })
//       .catch((error) => {
//         console.error('Error fetching blog metadata:', error);
//       });
//   }, []);

//   return (
//     <div>
//       <main className="main-wrapper" aria-label="main contents">
//         <div className="introduction-wrapper box">
//           <p className="logos">
//             <img src="/img/aboutmikkang.png" alt="" />
//           </p>
//         </div>

//         <article className="about-wrapper">
//           <div className="sogae-text box">
//             <span> ---------- ----------</span>
//             <br />
//             <strong>
//               안녕하세요?<br /> 방문 감사드리며 여기는 쇼핑몰 사이트는 아니구요 <br />
//               매년 귤/한라봉 지인분들께 소량 판매하는데 어떻게 하면 편하게 주문들 하시고 저도 조금 편하게 할까^^ 고민하다가...
//               간단하게 웹사이트로 만들어 봤습니다. <br /> <br />
//               회원 가입여부와 상관없이 주문은 가능하고, 주문내역에서 입금계좌/금액 확인가능합니다. 회원분들께는 다음 판매시점을
//               알려드리고 편하게 입력하실수 있는 기능들이 추가 제공됩니다.
//               <br />
//               감사합니다.
//               <br />
//             </strong>
//             <span> ---------- ----------</span>
//           </div>

//           <div className="aboutyangs box">
//             <img src="/img/AboutImage.jpg" alt="" />
//           </div>
//         </article>
//       </main>
//     </div>
//   );
// };

// export default About;


// src/pages/About.js
import React, { useEffect } from 'react';
import { getNode } from '../utils/useful-functions.js';

const About = () => {
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    const $aboutWrapper = getNode('.about-wrapper');
    const blogId = 'rokettory';
    const rssUrl = `https://rss.blog.naver.com/${blogId}.xml`;
    const defaultImageUrl = '/img/blog.png'; 
    const desiredCategory = '제주소식';
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(rssUrl)}`)
      .then((response) => response.json())
      .then((data) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data.contents, 'application/xml');
        const items = doc.querySelectorAll('item');

        items.forEach((item) => {
          const categories = Array.from(item.querySelectorAll('category')).map((cat) => cat.textContent);
          if (!categories.includes(desiredCategory) && subdomain !== 'localhost' && subdomain !== 'store') {
            return; 
          }

          const title = item.querySelector('title').textContent;
          const link = item.querySelector('link').textContent;
          const description = item.querySelector('description').textContent;

          const imgRegex = /<img[^>]+src="([^">]+)"/g;
          const imgMatch = imgRegex.exec(description);
          const imageUrl = imgMatch ? imgMatch[1] : defaultImageUrl;

          const textDescription = description.replace(/<[^>]*>?/gm, '');
          const shortDescription = textDescription.split(' ').slice(0, 15).join(' ') + '...';

          const blogLinkDiv = document.createElement('div');
          blogLinkDiv.classList.add('w-full', 'flex-shrink-0', 'mt-4','mb-2');
          blogLinkDiv.innerHTML = `
            <article class="flex bg-white p-4 rounded shadow">
              <figure class="flex-shrink-0 ">
                <img class="w-16 h-16 object-cover " src="${imageUrl}" alt="Blog Thumbnail">
              </figure>
              <div class="ml-4 flex-1">
                <p class="text-lg font-semibold">${title}</p>
                <div class="mt-1 text-gray-600">
                  ${shortDescription}
                <br>
                  <a href="${link}" target="_blank" class="text-blue-500 hover:underline">Read more</a>
                </div>
              </div>
            </article>
          `;
          $aboutWrapper.appendChild(blogLinkDiv);
        });
      })
      .catch((error) => {
        console.error('Error fetching blog metadata:', error);
      });
  }, []);

  return (
    <div>
      <main className="main-wrapper mt-16 flex flex-col items-center justify-center">
        <div className="bg-white mt-10 p-4 rounded shadow w-11/12 max-w-4xl">
            <img src="/img/aboutmikkang.png" className="w-full h-auto object-cover"  alt="" />
        </div>

        <article className="about-wrapper flex flex-col items-center justify-center w-11/12 max-w-4xl mt-5">
          <div className="sogae-text mt-2 border-t border-b border-red-300 py-2 flex flex-col items-center text-lg font-semibold mb-5 ">
            <span> ---------- ----------</span>
            <br />
            <strong>
              안녕하세요?<br /> 방문 감사드리며 여기는 쇼핑몰 사이트는 아니구요 <br />
              매년 귤/한라봉 지인분들께 소량 판매하는데 어떻게 하면 편하게 주문들 하시고 저도 조금 편하게 할까^^ 고민하다가...
              간단하게 웹사이트로 만들어 봤습니다. <br /> <br />
              회원 가입여부와 상관없이 주문은 가능하고, 주문내역에서 입금계좌/금액 확인가능합니다. 회원분들께는 다음 판매시점을
              알려드리고 편하게 입력하실수 있는 기능들이 추가 제공됩니다.
              <br />
              감사합니다.
              <br />
            </strong>
            <span> ---------- ----------</span>
          </div>

          <div className="bg-white p-4 rounded shadow  flex flex-col justify-center items-center overflow-hidden max-w-4xl">
            <img src="/img/AboutImage.jpg" alt="" />
          </div>
        </article>
      </main>
    </div>
  );
};

export default About;
