import React, { useState, useEffect } from 'react';
import * as Api from '../../utils/api.js';
import { addCommas } from '../../utils/useful-functions.js';
import config from '../../config/config.js'

const Orders = ({ role, companyId }) => {
  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState({ totalAmount: 0, totalPrice: 0 });
  const [activeTab, setActiveTab] = useState('입금대기');
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [counts, setCounts] = useState({
    입금대기: 0,
    주문접수: 0,
    배송중: 0,
    배송완료: 0,
    전체: 0,
    종료: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); 
  useEffect(() => {
    const loadOrders = async () => {
      setLoading(true);
      try {
        const searchQuery = getSearchQueryByTab(activeTab);
        const dummyPlusQuery = { ...searchQuery, dummy: '', page: currentPage, limit: 10 };  // 페이지와 페이지당 항목 수 추가
        const response = await Api.getYesToken(`/api/orders/list?${new URLSearchParams(dummyPlusQuery)}`);
        const fetchedOrders = response.data.orders;
        setOrders(fetchedOrders);
        setTotalPages(response.data.totalPages);  // 서버로부터 전체 페이지 수 가져오기
        updateSummary(fetchedOrders);
  
        // 카운트 업데이트
        setCounts((prevCounts) => ({
          ...prevCounts,
          [activeTab]: fetchedOrders.length,
        }));
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };
  
    loadOrders();
  }, [activeTab, currentPage]);  // currentPage 의존성 추가
  
  const getSearchQueryByTab = (tab) => {
    switch (tab) {
      case '입금대기':
        return { payStatus: '입금대기', active: true };
      case '주문접수':
        return { orderStatus: '주문접수', payStatus: '입금완료', active: true };
      case '배송중':
        return { orderStatus_not: ['주문접수', '배송완료'], payStatus: '입금완료', active: true };
      case '배송완료':
        return { orderStatus: '배송완료', payStatus: '입금완료', active: true };
      case '전체':
        return { active: true };
      case '종료':
        return { active: false };
      default:
        return {};
    }
  };

  const updateSummary = (orders) => {
    let totalAmount = 0;
    let totalPrice = 0;
    orders.forEach((order) => {
      totalAmount += order.products[0].count;
      totalPrice += order.products[0].productPrice;
    });
    setSummary({ totalAmount, totalPrice });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const updateOrderStatus = async (order) => {
    if (role !== 'admin') {
      window.alert('주문 상태는 관리자만 변경할 수 있습니다.');
      return;
    }
    console.log(order);
    const newStatus = getNextOrderStatus(order.orderStatus);
    const patchData = { orderStatus: newStatus };
  
    try {
      await Api.patchYesToken(`/api/orders/`,order.shortId, patchData);
      setOrders((prevOrders) =>
        prevOrders.map((o) => (o.shortId === order.shortId ? { ...o, orderStatus: newStatus } : o))
      );
      updateCounts(newStatus, order.orderStatus);  // 상단 카운트 업데이트
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  

  const updatePayStatus = async (order) => {
    if (role !== 'admin') {
      window.alert('입금 상태는 관리자만 변경할 수 있습니다.');
      return;
    }
  
    const newStatus = order.payStatus === '입금대기' ? '입금완료' : '입금대기';
    const patchData = { payStatus: newStatus };
  
    try {
      await Api.patchYesToken(`/api/orders/`,order.shortId, patchData);
      setOrders((prevOrders) =>
        prevOrders.map((o) => (o.shortId === order.shortId ? { ...o, payStatus: newStatus } : o))
      );
      updateCounts(newStatus, order.payStatus);  // 상단 카운트 업데이트
    } catch (error) {
      console.error('Error updating pay status:', error);
    }
  };
  const updateCounts = (newStatus, oldStatus) => {
    setCounts((prevCounts) => {
      const newCounts = { ...prevCounts };
  
      if (newStatus === '입금완료' && oldStatus === '입금대기') {
        newCounts['입금대기'] -= 1;
        newCounts['주문접수'] += 1;
      } else if (newStatus === '입금대기' && oldStatus === '입금완료') {
        newCounts['주문접수'] -= 1;
        newCounts['입금대기'] += 1;
      } else if (newStatus === '배송완료' && oldStatus === '배송중') {
        newCounts['배송중'] -= 1;
        newCounts['배송완료'] += 1;
      }
      return newCounts;
    });
  };
  const getNextOrderStatus = (currentStatus) => {
    switch (currentStatus) {
      case '주문접수':
        return '배송시작';
      case '배송시작':
        return '배송완료';
      case '배송완료':
        return '주문접수';
      default:
        return currentStatus;
    }
  };

  const getButtonColor = (status) => {
    switch (status) {
      case '주문접수':
        return 'is-light';
      case '배송시작':
        return 'is-warning';
      case '배송완료':
        return 'is-info';
      default:
        return 'is-light';
    }
  };

  const getPayButtonColor = (status) => {
    return status === '입금완료' ? 'is-info' : 'is-warning';
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  // const downloadExcel = async () => {
  //   try {
  //     const token = localStorage.getItem('accessToken');
  //     if (!token) {
  //       throw new Error('토큰이 존재하지 않습니다. 로그인해주세요.');
  //     }
  
  //     const response = await fetch('/api/orders/download-excel', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  
  //     if (!response.ok) {
  //       throw new Error('네트워크 응답이 올바르지 않습니다.');
  //     }
  
  //     const blob = await response.blob();
  
  //     // 모바일 Safari를 위한 Blob 처리
  //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //       // Internet Explorer 및 Edge 처리 방식
  //       window.navigator.msSaveOrOpenBlob(blob, `downloaded_orders_${getFormattedDate()}.xlsx`);
  //     } else {
  //       const reader = new FileReader();
  //       reader.onloadend = function() {
  //         const a = document.createElement('a');
  //         a.href = reader.result; // Base64 데이터 사용
  //         a.download = `downloaded_orders_${getFormattedDate()}.xlsx`;
  //         document.body.appendChild(a);
  //         a.click();
  //         a.remove();
  //       };
  //       reader.readAsDataURL(blob); // Blob을 Base64로 변환
  //     }
  //   } catch (error) {
  //     window.alert('파일 다운로드 실패: ' + error.message);
  //   }
  // };
  
  const downloadExcel = async () => {
    const {API_URL} = config;
    try {
      const response = await fetch(API_URL+'/api/orders/download-excel', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (!response.ok) {
        throw new Error('네트워크 응답이 올바르지 않습니다.');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `downloaded_orders_${getFormattedDate()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      window.alert('파일 다운로드 실패: ' + error.message);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const uploadExcel = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch(config.API_URL+'/api/songjang/upload', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      await Api.postYesToken('/api/orders/songjangupload', { filePath: result.fileName });
      window.alert('송장번호 업로드를 정상적으로 완료했습니다.');
      handleModalClose();
      window.location.reload();
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <div>
      <div className="tabs is-boxed">
        <ul>
          {['입금대기', '주문접수', '배송중', '배송완료', '전체', '종료'].map((tab) => (
            <li key={tab} className={activeTab === tab ? 'is-active' : ''}>
              <a onClick={() => handleTabClick(tab)}>
                <span>{tab}</span>
                <span>({counts[tab] || 0})</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="summary-box" style={{ margin: '0.5rem 0', padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
        <div className="summary-text" style={{ fontSize: '1rem', fontWeight: '600' }}>
          [수량: {summary.totalAmount} / 금액: {addCommas(summary.totalPrice)}원]
        </div>
        <div className="button-wrapper" style={{ display: 'flex', flexDirection: 'row' }}>
          <button className="button is-small is-link" style={{ padding: '5px', fontSize: '0.7rem', margin: '0 2px' }} onClick={downloadExcel}>
            다운로드
          </button>
          <button
            className="button is-small is-dark"
            style={{ padding: '5px', fontSize: '0.7rem', margin: '0 2px', display: activeTab === '주문접수' ? 'block' : 'none' }}
            onClick={handleModalOpen}
          >
            송장업로드
          </button>
        </div>
      </div>

      <table className="table is-fullwidth" style={{ fontSize: '0.7rem' }}>
        <thead>
          <tr>
            <th id="pc-mobile">주문일</th>
            <th id="pc-mobile">주문자</th>
            <th id="pc-mobile">품목</th>
            <th id="pc-mobile">수량</th>
            <th id="pc-mobile">총금액</th>
            <th id="pc-only">보낸사람</th>
            <th id="pc-only">보낸사람 연락처</th>
            <th id="pc-mobile">받는사람</th>
            <th id="pc-mobile">받는사람 연락처</th>
            <th id="pc-only">주소</th>
            <th id="pc-mobile">주문상태</th>
            <th id="pc-mobile">입금상태</th>
            <th id="pc-mobile">추천인</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="13">Loading...</td>
            </tr>
          ) : (
            orders.map((order) => (
              <tr key={order.shortId}>
                <td id="pc-mobile">{order.createdAt.substr(0, 10)}</td>
                <td id="pc-mobile">{order.fullName}</td>
                <td id="pc-mobile">{order.products[0].productName}</td>
                <td id="pc-mobile">{order.products[0].count}</td>
                <td id="pc-mobile">{addCommas(order.products[0].productPrice)}</td>
                <td id="pc-only">{order.senderName}</td>
                <td id="pc-only">{order.phoneNumber}</td>
                <td id="pc-mobile">{order.recipientName}</td>
                <td id="pc-mobile">{order.recipientPhoneNumber}</td>
                <td id="pc-only">{`${order.address.address1} ${order.address.address2}`}</td>
                <td id="pc-mobile">
                  <button
                    className={`button is-small ${getButtonColor(order.orderStatus)}`}
                    onClick={() => updateOrderStatus(order)}
                  >
                    {order.orderStatus}
                  </button>
                </td>
                <td id="pc-mobile">
                  <button
                    className={`button is-small ${getPayButtonColor(order.payStatus)}`}
                    onClick={() => updatePayStatus(order)}
                  >
                    {order.payStatus}
                  </button>
                </td>
                <td>{order.recommender}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>



<div className="pagination is-small" role="navigation" aria-label="pagination">
  <a
    href="#"
    className="pagination-previous"
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  >
    Previous
  </a>
  <a
    href="#"
    className="pagination-next"
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  >
    Next
  </a>
  <ul className="pagination-list">
    {/* 첫 번째 페이지 */}
    <li>
      <a
        href="#"
        className={`pagination-link ${currentPage === 1 ? 'is-current' : ''}`}
        aria-label="Goto page 1"
        onClick={() => handlePageChange(1)}
      >
        First
      </a>
    </li>

    {/* 페이지 중간의 "..." 처리 */}
    {currentPage > 3 && (
      <li>
        <span className="pagination-ellipsis">&hellip;</span>
      </li>
    )}

    {/* 현재 페이지 근처의 페이지들 */}
    {Array.from({ length: totalPages }, (_, index) => {
      const page = index + 1;
      if (page < currentPage - 1 || page > currentPage + 1) return null;

      return (
        <li key={page}>
          <a
            href="#"
            className={`pagination-link ${currentPage === page ? 'is-current' : ''}`}
            aria-label={`Goto page ${page}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </a>
        </li>
      );
    })}

    {/* 페이지 끝의 "..." 처리 */}
    {currentPage < totalPages - 2 && (
      <li>
        <span className="pagination-ellipsis">&hellip;</span>
      </li>
    )}

    {/* 마지막 페이지 */}
    {totalPages > 1 && (
      <li>
        <a
          href="#"
          className={`pagination-link ${currentPage === totalPages ? 'is-current' : ''}`}
          aria-label={`Goto page ${totalPages}`}
          onClick={() => handlePageChange(totalPages)}
        >
          End({totalPages})
        </a>
      </li>
    )}
  </ul>
</div>



      {modalVisible && (
        <div className="modal is-active">
          <div className="modal-background" onClick={handleModalClose}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">송장 업로드</p>
              <button className="delete" aria-label="close" onClick={handleModalClose}></button>
            </header>
            <section className="modal-card-body">
              <form id="excelUploadForm" onSubmit={uploadExcel}>
                <input type="file" name="excelFile" accept=".xlsx, .xls" />
                <button type="submit" className="button is-dark">업로드</button>
              </form>
            </section>
          </div>
        </div>
      )}
    </div>
    
  );
};

const getFormattedDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${year}${month}${day}_${hours}${minutes}`;
};

export default Orders;
