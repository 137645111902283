import React from 'react';

const ImageResizer = ({ onImageResize, imageFile, imagePreview, setImageFile, setImagePreview, targetWidth = 1024}) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const imgName = file.name;
    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const aspectRatio = img.width / img.height;
        const targetHeight = targetWidth / aspectRatio;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        let mimeType = 'image/jpeg';
        if (imgName.toLowerCase().endsWith('.png')) {
          mimeType = 'image/png';
        }

        canvas.toBlob(function (blob) {
          const resizedFile = new File([blob], imgName, { type: mimeType });
          setImageFile(resizedFile);
          setImagePreview(window.URL.createObjectURL(resizedFile));
          onImageResize(resizedFile);
        }, mimeType, 0.8);
      };
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="file has-name image-uploader">
      <div className="img-preview-wrap" style={{width:'100px'}}>
        {imagePreview && (
          <img className="img-preview" src={imagePreview} alt="Preview" />
        )}
        {imagePreview && (
          <span
            className="material-icons cancel-img"
            onClick={() => {
              setImageFile(null);
              setImagePreview('');
            }}
          >
            cancel
          </span>
        )}
      </div>
      <label className="file-label">
        <input className="file-input" type="file" accept="image/*" onChange={handleImageChange} />
        <span className="file-cta">
          <span className="file-icon">
            <i className="fas fa-upload"></i>
          </span>
          <span className="file-label">사진 업로드</span>
        </span>
        <span className="file-name">
          {imageFile ? imageFile.name : '파일 이름'}
        </span>
      </label>
    </div>
  );
};

export default ImageResizer;