import React, { useState, useEffect } from 'react';
import * as Api from '../../utils/api.js';
import ImageResizer from '../../components/ImageResizer'; // 이미지 리사이저 컴포넌트 임포트

const Homes = ({ role }) => {
  const [homes, setHomes] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [selectedHome, setSelectedHome] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [homeName, setHomeName] = useState('');
  const [subtitleMain, setSubtitleMain] = useState('');
  const [subtitleSub, setSubtitleSub] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImagePreview, setBackgroundImagePreview] = useState('');
  const [swiperImages, setSwiperImages] = useState(Array(5).fill(null));
  const [swiperImagesPreviews, setSwiperImagesPreviews] = useState(Array(5).fill(''));

  const fetchHomes = async () => {
    try {
        const response = await Api.getYesToken('/api/homes/list');
        setHomes(response.data);
    } catch (error) {
        console.error('Error fetching homes:', error);
    }
};
  useEffect(() => {

      fetchHomes();
  }, []);

  const openHomeEditModal = (home = null) => {
      if (home) {
          setSelectedHome(home);
          setIsEditMode(true);
          setHomeName(home.homeName);
          setSubtitleMain(home.subtitleMain);
          setSubtitleSub(home.subtitleSub);
          setBackgroundImage(null);
          setBackgroundImagePreview(home.backgroundImage || '');
          setSwiperImages(Array(5).fill(null));
          setSwiperImagesPreviews(home.swiperImages || Array(5).fill(''));
      } else {
          setSelectedHome(null);
          setIsEditMode(false);
          setHomeName('');
          setSubtitleMain('');
          setSubtitleSub('');
          setBackgroundImage(null);
          setBackgroundImagePreview('');
          setSwiperImages(Array(5).fill(null));
          setSwiperImagesPreviews(Array(5).fill(''));
      }
      setActiveModal(true);
  };

  const closeEditModal = () => {
      setActiveModal(false);
      setSelectedHome(null);
      fetchHomes();
  };

  const handleImageResize = (resizedImage, index = null) => {
    if (index === null) {
      setBackgroundImage(resizedImage);
      setBackgroundImagePreview(URL.createObjectURL(resizedImage));
    } else {
      const updatedSwiperImages = [...swiperImages];
      const updatedSwiperImagesPreviews = [...swiperImagesPreviews];
      updatedSwiperImages[index] = resizedImage;
      updatedSwiperImagesPreviews[index] = (URL.createObjectURL(resizedImage));
      setSwiperImages(updatedSwiperImages);
      setSwiperImagesPreviews(updatedSwiperImagesPreviews);
    }
  };

  const uploadImageToS3 = async (division, index = 0, homeName) => {
    const folderName = `homes/${homeName}`;
    const formData = new FormData();  // FormData 생성
  
    if (division === 'background') {
        if (!backgroundImage) return '';
        formData.append('image', backgroundImage);  // 이미지 파일을 FormData에 추가
  
        try {
            const result = await Api.postNoTokenFile(`/api/images/upload?folderName=${folderName}`, formData);  // FormData 바로 전달
            return result.imagePath;  // S3에 저장된 이미지 경로 반환
        } catch (err) {
            console.log(err.message);
        } finally {
            formData.delete('image');  // 올바르게 'image'로 삭제
        }
    } else if (division === 'swiper') {
        if (!swiperImages[index]) return '';
        formData.append('image', swiperImages[index]);  // Swiper 이미지 파일을 FormData에 추가
  
        try {
            const result = await Api.postNoTokenFile(`/api/images/upload?folderName=${folderName}`, formData);  // FormData 바로 전달
            return result.imagePath;  // S3에 저장된 이미지 경로 반환
        } catch (err) {
            console.log(err.message);
        } finally {
            formData.delete('image');  // 올바르게 'image'로 삭제
        }
    }
};

  

  const handleSubmit = async () => {
      const formData = {
          homeName,
          subtitleMain,
          subtitleSub,
          backgroundImage: backgroundImage ? await uploadImageToS3('background',"",homeName) : selectedHome?.backgroundImage,
          swiperImages: await Promise.all(
              swiperImages.map((image, index) => image ? uploadImageToS3('swiper',index,homeName) : selectedHome?.swiperImages[index])
          ),
      };

      console.log('formData',formData) ;
      try {
          if (isEditMode) {
              await Api.patchYesToken(`/api/homes`, selectedHome.shortId, formData);
          } else {
              await Api.postYesToken(`/api/homes`, formData);
          }
          closeEditModal();
      } catch (error) {
          console.error('Error saving home:', error);
      }
  };

  const renderHomeRows = () => {
      return homes.map(home => (
          <tr key={home.shortId}>
              <td id="pc-only">{home.shortId}</td>
              <td id="pc-mobile">{home.homeName}</td>
              <td id="pc-mobile">{home.subtitleMain}</td>
              <td id="pc-mobile">{home.subtitleSub}</td>
              <td id="pc-mobile">
                  <img className="product-img-box" src={home.backgroundImage} alt="배경 이미지" />
              </td>
              <td id="pc-only">
                  {home.swiperImages.map((img, index) => (
                      <img key={index} className="product-img-box" src={img} alt={`Swiper Image ${index + 1}`} />
                  ))}
              </td>
              <td id="pc-only">{home.editorId}</td>
              <td id="pc-mobile">
                  <button className={`button is-small ${home.active ? 'is-info' : 'is-warning'}`}>
                      {home.active ? 'Active' : 'Inactive'}
                  </button>
              </td>
              <td id="pc-mobile">
                  <button className="button is-small" onClick={() => openHomeEditModal(home)}>
                      상세
                  </button>
              </td>
          </tr>
      ));
  };

  return (
      <div className="container rendering">
          <div className="tabs is-boxed">
              <ul>
                  <li className="companyrender-full-list is-active">
                      <a>
                          <span className="icon is-small">
                              <i className="fa-regular fa-user" aria-hidden="true"></i>
                          </span>
                          <span id="totalcount">전체({homes.length})</span>
                      </a>
                  </li>
                  {role === 'admin' && (
                      <li>
                          <a className="homes-create" onClick={() => openHomeEditModal()}>
                              <span className="icon is-small">
                                  <i className="fa fa-plus-square" aria-hidden="true"></i>
                              </span>
                              <span>신규 등록</span>
                          </a>
                      </li>
                  )}
              </ul>
          </div>

          <table className="table is-fullwidth" id="order-list-wrapper">
              <thead>
                  <tr>
                      <th id="pc-only">shortId</th>
                      <th id="pc-mobile">홈페이지명</th>
                      <th id="pc-mobile">메인타이틀</th>
                      <th id="pc-mobile">서브타이틀</th>
                      <th id="pc-mobile">배경화면</th>
                      <th id="pc-only">슬라이드 Images</th>
                      <th id="pc-only">등록(수정)자</th>
                      <th id="pc-mobile">Active</th>
                      <th id="pc-mobile">상세수정</th>              
                  </tr>
              </thead>
              <tbody>{renderHomeRows()}</tbody>
          </table>

          {activeModal && (
              <div className="modal is-active">
                  <div className="modal-background" onClick={closeEditModal}></div>
                  <div className="modal-card">
                      <header className="modal-card-head">
                          <p className="modal-card-title">
                              {isEditMode ? '홈페이지 수정' : '신규 홈페이지 등록'}
                          </p>
                          <button className="delete" aria-label="close" onClick={closeEditModal}></button>
                      </header>
                      <section className="modal-card-body">
                          <div className="field">
                              <label className="label">홈페이지명</label>
                              <input
                                  className="input"
                                  type="text"
                                  value={homeName}
                                  onChange={(e) => setHomeName(e.target.value)}
                              />
                          </div>
                          <div className="field">
                              <label className="label">메인 소개글</label>
                              <textarea
                                  className="textarea"
                                  value={subtitleMain}
                                  onChange={(e) => setSubtitleMain(e.target.value)}
                              ></textarea>
                          </div>
                          <div className="field">
                              <label className="label">서브 소개글</label>
                              <textarea
                                  className="textarea"
                                  value={subtitleSub}
                                  onChange={(e) => setSubtitleSub(e.target.value)}
                              ></textarea>
                          </div>

                        <div className="field">
                        <label className="label">배경화면</label>
                            <ImageResizer
                                imageFile={backgroundImage}  // 현재 이미지 파일을 전달
                                imagePreview={backgroundImagePreview}  // 미리보기 URL을 전달
                                setImageFile={setBackgroundImage}  // 이미지 파일 상태 업데이트
                                setImagePreview={setBackgroundImagePreview}  // 미리보기 상태 업데이트
                                targetWidth={1024}
                                onImageResize={(resizedImage) => handleImageResize(resizedImage)}  // 리사이즈된 이미지를 상위 컴포넌트로 전달
                             />
                        </div>

                        {[...Array(5)].map((_, index) => (
                        <div key={index} className="field">
                            <label className="label">Swiper 이미지 {index + 1}</label>
                            <ImageResizer
                                imageFile={swiperImages[index]}  // 현재 swiper 이미지 파일을 전달
                                imagePreview={swiperImagesPreviews[index]}  // 미리보기 URL을 전달
                                setImageFile={(resizedImage) => {
                                    const updatedSwiperImages = [...swiperImages];
                                    updatedSwiperImages[index] = resizedImage;
                                    setSwiperImages(updatedSwiperImages);  // 리사이즈된 이미지 상태 업데이트
                                }}
                                setImagePreview={(preview) => {
                                    const updatedSwiperPreviews = [...swiperImagesPreviews];
                                    updatedSwiperPreviews[index] = preview;
                                    setSwiperImagesPreviews(updatedSwiperPreviews);  // 미리보기 상태 업데이트
                                }}
                                targetWidth={1024}
                                onImageResize={(resizedImage) => {
                                const updatedSwiperImages = [...swiperImages];
                                updatedSwiperImages[index] = resizedImage;
                                handleImageResize(resizedImage, index);  // onImageResize로 리사이즈된 이미지를 상태 업데이트
                                }}
                             />
                        </div>
                        ))}

 
                      </section>
                      <footer className="modal-card-foot">
                          <button className="button is-success" onClick={handleSubmit}>
                              {isEditMode ? '수정하기' : '등록하기'}
                          </button>
                          <button className="button" onClick={closeEditModal}>
                              취소
                          </button>
                      </footer>
                  </div>
              </div>
          )}
      </div>
  );
};

export default Homes;
