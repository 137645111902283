import React, { useState } from 'react';

const StarRating = ({ reviewScore, setReviewScore ,editable}) => {
  const [hover, setHover] = useState(null);

  // 별을 렌더링하는 함수
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className="star"
          
          onClick={() => editable? setReviewScore(i) : null}
          onMouseEnter={() =>  editable? setHover(i) : null}
          onMouseLeave={() => editable? setHover(null) : null}
          style={{ cursor: 'pointer', color: i <= (hover || reviewScore) ? 'gold' : 'rgb(240, 240, 240)' }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;
