import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationObj, deleteCookie, getCookie } from '../utils/useful-functions.js';
import * as Api from '../utils/api.js';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyB7BqrBe14ExpJV5hyBR7Xd5gg0tN_KZ5w",
  authDomain: "yangsfarm-3a824.firebaseapp.com",
  projectId: "yangsfarm-3a824",
  storageBucket: "yangsfarm-3a824.appspot.com",
  messagingSenderId: "226385493837",
  appId: "1:226385493837:web:9cd17d3967ba1badc80104"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// 로그아웃 기능
const logOut = (navigate) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('accessToken_exp');
  localStorage.removeItem('userPhone');
  localStorage.removeItem('userRole');
  localStorage.removeItem('userName');
  localStorage.removeItem('companyId');
  localStorage.removeItem('fcmToken');
  localStorage.removeItem('nextDisplayDate');
  deleteCookie('refreshToken');
  deleteCookie('accessToken');
  navigate('/login');
};

// FCM 초기화 및 토큰 설정
const initFirebaseMessagingRegistration = async () => {
  try {
    const registration = await navigator.serviceWorker.ready;
    const currentToken = await getToken(messaging, { 
      vapidKey: 'BAJCK23mT4AgH4UcI8IoOmqXE6wmMNfdCGnvkJWK6T7lIskg5sQbuTY932F1uIJL54KqZTu3LWFM217h0vqybWM', 
      serviceWorkerRegistration: registration 
    });

    if (currentToken) {
      localStorage.setItem('fcmToken', currentToken);
      await sendTokenToServer(currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

// FCM 토큰을 서버로 전송
const sendTokenToServer = async (newToken) => {
  try {
    const result = await Api.getYesToken('/api/users?attribute=fcmToken');
    const currentFcmToken = result.data.fcmToken;
    if (newToken !== currentFcmToken) {
      await Api.patchYesToken('/api/users/fcmtoken', '', { fcmToken: newToken });
    }
  } catch (error) {
    console.error(error.message);
    window.alert(`FCM 토큰 업데이트 실패 ${error.message}`);
  }
};

// 알림 메시지 수신
const fcmOnMessage = () => {
  onMessage(messaging, (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon || '/img/mikkang-logo-192.png'
    };

    if (Notification.permission === 'granted') {
      new Notification(notificationTitle, notificationOptions);
    } else {
      // In-page 알림 표시
      const notificationElement = document.createElement('div');
      notificationElement.className = 'in-page-notification';
      notificationElement.innerHTML = `
        <h4>${notificationTitle}</h4>
        <p>${notificationOptions.body}</p>
      `;
      document.body.appendChild(notificationElement);
      setTimeout(() => {
        document.body.removeChild(notificationElement);
      }, 5000);
    }
  });
};

// 스타일 추가
const addNotificationStyles = () => {
  const style = document.createElement('style');
  style.innerHTML = `
    .in-page-notification {
      position: fixed;
      bottom: 10px;
      right: 10px;
      background: #444;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      z-index: 1000;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  `;
  document.head.appendChild(style);
};

// Navbar 관리 컴포넌트
const NavAdmin = ({ onCategorySelect }) => {
  const [isLogin, setIsLogin] = useState(false);
   const storedUserPhone = localStorage.getItem('userPhone');
  const storedUserRole = localStorage.getItem('userRole');
  const isAdminUser = (storedUserRole === 'admin') && isLogin;

  const navigate = useNavigate();

  useEffect(() => {
    const { isLogin} = getAuthorizationObj();
    setIsLogin(isLogin);
     if (isLogin) {
      initFirebaseMessagingRegistration();
      addNotificationStyles();
      fcmOnMessage();
    }

    const toggleNavbar = () => {
      const burgers = document.querySelectorAll('.navbar-burger');
      const menus = document.querySelectorAll('.navbar-menu');
  
      burgers.forEach((burger) => {
        burger.addEventListener('click', () => {
          burger.classList.toggle('is-active');
          menus.forEach((menu) => menu.classList.toggle('is-active'));
        });
      });
  
      // Add event listener to close the burger menu after clicking a menu item
      const menuItems = document.querySelectorAll('.navbar-item');
      menuItems.forEach((item) => {
        item.addEventListener('click', () => {
          // Close the burger menu after selecting an item
          burgers.forEach((burger) => burger.classList.remove('is-active'));
          menus.forEach((menu) => menu.classList.remove('is-active'));
        });
      });
    };
  
    toggleNavbar();
  
  }, []);

  return (
    <nav className="navbar" style={{ position: 'fixed', top: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
      <div className="container mt-3">
        <div className="navbar-brand" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a className="navbar-item" href="/admin">
            <img style={{ maxWidth: '100%', maxHeight: '2.5em' }} src="/img/yangsfarm_logo.png" alt="LOGO" />
          </a>
          <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample" className="navbar-menu" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
        <a className="navbar-item" onClick={() => onCategorySelect('주문')}>주문</a>
      <a className="navbar-item" onClick={() => onCategorySelect('상품')}>상품</a>
      <a className="navbar-item" onClick={() => onCategorySelect('문의')}>문의</a>
      <a className="navbar-item" onClick={() => onCategorySelect('판매자')}>판매자</a>
      <a className="navbar-item" onClick={() => onCategorySelect('홈편집')}>홈편집</a>       
      {isAdminUser && ( <a className="navbar-item" onClick={() => onCategorySelect('회원')}>회원</a>)}
      {isAdminUser && ( <a className="navbar-item" onClick={() => onCategorySelect('카테고리')}>카테고리</a>)}
      {isAdminUser && ( <a className="navbar-item" onClick={() => onCategorySelect('공지')}>공지</a>)}

          {isLogin ? (
            <div className="navbar-item has-dropdown is-hoverable">
              <span className="material-icons navbar-link">account_circle</span>
              <div className="navbar-dropdown">
                <a className="navbar-item" href="/mypage">내 정보관리</a>
                <a className="navbar-item" onClick={() => logOut(navigate)}>로그 아웃</a>
                <div className="navbar-item buildtime"></div>
              </div>
              <div className="navbar-item button is-small is-success">
                {storedUserPhone}님
              </div>
              <div className="navbar-item button is-small is-warning">
                <a href="/"> 홈페이지 </a>
              </div>
            </div>
          ) : (
            <>
              <a className="navbar-item" href="/login">
                <span className="material-icons">account_circle</span>
              </a>
              <div className="navbar-item button is-small is-warning">
                <a href="/login"> 로그인 </a>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavAdmin;
